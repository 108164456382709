import { updateAggregationState } from '../../../functions'
import {
    ADD_APPLICATION_PRINTER,
    ADD_APPLICATION_SCANNER,
    ADD_MULTISCANNER,
    CHANGE_AGGREGATION_TYPE,
    CHANGE_APPLICATION_SCANNER_MODE,
    CHANGE_APPLICATION_TYPE,
    CHANGE_MULTISCANNER_MODE,
    CHANGE_MULTISCANNING_TYPE,
    DELETE_APPLICATION_PRINTER,
    DELETE_APPLICATION_SCANNER,
    DELETE_MULTISCANNER,
    SET_All_AGGREGATION_SETTINGS,
    SET_FLOAT_NUMBER_PACKAGES,
    UPDATE_APPLICATION_PRINTER_DATA,
    UPDATE_APPLICATION_SCANNER_DATA,
    UPDATE_MULTISCANNER_DATA,
    UPDATE_NUMBER_PACKAGES
} from './aggregationSettingsActions'

const initialState = [{}, {}]

export const aggregationSettingsReducer = (state = initialState, action) => {
    const arr = [...state]
    const printers = arr[action.aggregationLevel - 1]?.application?.printers ? arr[action.aggregationLevel - 1].application.printers : []
    const scanners = arr[action.aggregationLevel - 1]?.multiScanning?.scanners ? arr[action.aggregationLevel - 1]?.multiScanning?.scanners : []
    const actionSettings = action?.settings?.length === 0 ? initialState : action.settings;
    switch (action.type) {
        case SET_All_AGGREGATION_SETTINGS:
            return [...actionSettings]
        case UPDATE_NUMBER_PACKAGES:
            return updateAggregationState(state, action, 'numberPackagesInAggregates', 'numberPackagesInAggregates')
        case CHANGE_MULTISCANNING_TYPE:
            arr[action.aggregationLevel - 1].multiScanning = {
                ...arr[action.aggregationLevel - 1].multiScanning,
                type: action.scanningType
            }
            return arr
        case SET_FLOAT_NUMBER_PACKAGES:
            return updateAggregationState(state, action, 'noFixedNumberPackagesInAggregates', 'isFloat')
        case ADD_MULTISCANNER:
            arr[action.aggregationLevel - 1].multiScanning = {
                ...arr[action.aggregationLevel - 1].multiScanning,
                type: action.scannerType,
                scanners: [...scanners, action.newScanner]
            }
            return arr
        case ADD_APPLICATION_PRINTER:

            arr[action.aggregationLevel - 1].application = {
                ...(arr[action.aggregationLevel - 1].application ? arr[action.aggregationLevel - 1].application : {}),
                printers: [...printers, action.newPrinter]
            }
            return arr
        case ADD_APPLICATION_SCANNER:
              arr[action.aggregationLevel - 1].application = {
                ...arr[action.aggregationLevel - 1].application,
                scanners: [...arr[action.aggregationLevel - 1].application.scanners, action.newScanner]
            }
            return arr
        case DELETE_MULTISCANNER:
            arr[action.aggregationLevel - 1].multiScanning = {
                ...arr[action.aggregationLevel - 1].multiScanning,
                type: action.scannerType,
                scanners: [...arr[action.aggregationLevel - 1].multiScanning.scanners.filter(item => +item.id !== +action.scannerID)]
            }
            return arr
        case DELETE_APPLICATION_PRINTER:
            arr[action.aggregationLevel - 1].application = {
                ...arr[action.aggregationLevel - 1].application,
                type: action.applicationType,
                printers: [...arr[action.aggregationLevel - 1].application.printers.filter(item => +item.id !== +action.printerID)]
            }
            return arr
        case DELETE_APPLICATION_SCANNER:
            arr[action.aggregationLevel - 1].application = {
                ...arr[action.aggregationLevel - 1].application,
                type: action.applicationType,
                scanners: [...arr[action.aggregationLevel - 1].application.scanners.filter(item => +item.id !== +action.scannerID)]
            }
            return arr
        case CHANGE_MULTISCANNER_MODE:
            arr[action.aggregationLevel - 1].multiScanning = {
                ...arr[action.aggregationLevel - 1].multiScanning,
                scanners: [...arr[action.aggregationLevel - 1].multiScanning.scanners.map(item => +item.id === +action.scannerID ? {
                    ...item,
                    mode: action.scannerMode
                } : item)]
            }
            return arr
        case CHANGE_APPLICATION_SCANNER_MODE:
            arr[action.aggregationLevel - 1].application = {
                ...arr[action.aggregationLevel - 1].application,
                scanners: [...arr[action.aggregationLevel - 1].application.scanners.map(item => +item.id === +action.scannerID ? {
                    ...item,
                    mode: action.scannerMode
                } : item)]
            }
            return arr
        case CHANGE_AGGREGATION_TYPE:
            return updateAggregationState(state, action, 'type', 'aggregationType')
        case CHANGE_APPLICATION_TYPE:
            arr[action.aggregationLevel - 1].application = {
                ...arr[action.aggregationLevel - 1].application,
                type: action.applicationType
            }
            return arr
        case UPDATE_MULTISCANNER_DATA:
            arr[action.aggregationLevel - 1].multiScanning = {
                ...arr[action.aggregationLevel - 1].multiScanning,
                scanners: [...arr[action.aggregationLevel - 1].multiScanning.scanners.map(item => +item.id === +action.updatedScanner.id ? action.updatedScanner : item)]
            }
            return arr
        case UPDATE_APPLICATION_PRINTER_DATA:
            arr[action.aggregationLevel - 1].application = {
                ...arr[action.aggregationLevel - 1].application,
                type: action.applicationType,
                printers: [...arr[action.aggregationLevel - 1].application.printers.map(item => +item.id === +action.updatedPrinter.id ? action.updatedPrinter : item)]
            }
            return arr
        case UPDATE_APPLICATION_SCANNER_DATA:
            arr[action.aggregationLevel - 1].application = {
                ...arr[action.aggregationLevel - 1].application,
                type: action.applicationType,
                scanners: [...arr[action.aggregationLevel - 1].application.scanners.map(item => +item.id === +action.updatedScanner.id ? action.updatedScanner : item)]
            }
            return arr
        default:
            return state
    }
}






