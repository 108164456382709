import clsx from 'clsx'
import React from 'react';
import s from '../Control/Control.module.scss'

const SaveBtn = ({title="Сохранить", disabled, onClick, error}) => {
    return (
        <button disabled={disabled} onClick={onClick} type="submit" className={clsx(s.saveBtn, error ? s.error : '')}>{title}</button>
    );
};

export default SaveBtn;
