import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeWS, ws } from "./api/ws";
import { closeReleaseWS, ws_release } from "api/ws-realease";
import "./assets/styles/Main.module.scss";
import { Auth } from "./components/Auth/Auth";
import { Alert } from "./components/Common/Alerts/Alert/Alert";
import Loader from "./components/Common/Loader/Loader";
import Header from "./components/Header/Header";
import Root from "./components/Root/Root";
import {
  setAppLoading,
  setErrorText,
  setErrorTextSocket,
} from "./redux/App/appActionCreators";
import {
  getErrorText,
  getErrorTextSocket,
  getIsLoading,
} from "./redux/App/appSelectors";
import {
  checkLogin,
  login,
  setAuthErrorText,
  setIsLoggedAC,
} from "./redux/Auth/authActionCreators";
import {
  getAuthErrorText,
  getIsAuthLoading,
  getIsLogged,
} from "./redux/Auth/authSelectors";
import { useDisablePreloader } from "./hooks";
import { store } from "./redux/store";
import { getIsClient } from "./redux/Settings/BaseSettings/baseSettingsActionCreators";
import { stopLine } from "./redux/Line/lineActionCreators";
import { runReleaseWS } from "./redux/App/releaseActionCreators";
import { getShouldRetryWS } from "./redux/App/appSelectors";
import AutoUpdate from "redux/App/Autoupdate/Autoupdate.index";
import { logout } from "./redux/Auth/authActionCreators";

export const handleErrorFunc = (statusCode, message) => {
  if (!statusCode) {
    store.dispatch(setErrorText(message));
  } else if (statusCode === 403) {
    if (ws) {
      closeWS();
    }
    store.dispatch(setAuthErrorText(message));
    store.dispatch(setIsLoggedAC(false));
  } else if (statusCode === 400) {
    // store.dispatch(setIsLoggedAC(false))
    store.dispatch(setErrorText(message));
  } else if (statusCode === 401) {
    console.log("before logout");
    store.dispatch(logout());
  } else {
    store.dispatch(setErrorText(message));
  }
  store.dispatch(setAppLoading(false));
};

let retryTimeout;

export const App = () => {
  const dispatch = useDispatch();
  const logged = useSelector(getIsLogged);
  const loading = useSelector(getIsLoading);
  const authLoading = useSelector(getIsAuthLoading);
  const authErrorText = useSelector(getAuthErrorText);
  const errorText = useSelector(getErrorText);
  const errorTextSocket = useSelector(getErrorTextSocket);
  const [isOpenedHelp, setIsOpenedHelp] = useState(false);
  const { View } = useDisablePreloader();
  const shouldRetryWS = useSelector(getShouldRetryWS);
  const [showAlert, setShowAlert] = useState(false);

  console.log("errorText", errorText);
  console.log("errorTextSoket", errorTextSocket);

  useEffect(() => {
    dispatch(checkLogin());
    window.onbeforeunload = function () {
      return false;
    };

    return () => {
      if (ws) closeWS();
      if (ws_release) closeReleaseWS();
    };
  }, [dispatch]);

  useEffect(() => {
    if (shouldRetryWS) {
      clearTimeout(retryTimeout);
      retryTimeout = setInterval(() => {
        dispatch(runReleaseWS());
      }, 5000);
    } else {
      clearTimeout(retryTimeout);
    }
  }, [shouldRetryWS]);

  useEffect(() => {
    if (logged) {
      dispatch(getIsClient());
      dispatch(runReleaseWS());
    }
  }, [logged]);

  return (
    <>
      <AutoUpdate
        isLogged={logged}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
      {loading && !showAlert && <Loader />}
      {logged ? (
        <>
          <Header setIsOpenedHelp={setIsOpenedHelp} />
          <Root isOpenedHelp={isOpenedHelp} setIsOpenedHelp={setIsOpenedHelp} />
          <View />
        </>
      ) : (
        <Auth
          login={login}
          errorText={authErrorText}
          isLoading={authLoading && !showAlert}
        />
      )}
      {!!errorText && !showAlert && (
        <Alert
          text={errorText}
          closeText={"Закрыть"}
          onClose={() => dispatch(setErrorText(""))}
        />
      )}
      {!!errorTextSocket &&
        errorTextSocket !== "Соединение с сервером восстановлено" && (
          <Alert
            text={errorTextSocket}
            closeText={"Ок"}
            onClose={() => {
              // dispatch(stopLine())
              dispatch(setErrorTextSocket(""));
            }}
          />
        )}
    </>
  );
};
