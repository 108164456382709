import AuthAPI from "../../api/AuthAPI";
import { LOCAL_STORAGE_DATA_NAME } from "../../constants";
import { getAccessLevel } from "../../functions";
import { TokenTimer } from "../../utils/TokenTimer";
import {
  openSettings,
  setAllLines,
  setErrorText,
  setUserAC,
} from "../App/appActionCreators";
import { getSelectedLine, resetLine } from "../Line/lineActionCreators";
import {
  SET_ACCESS_LEVEL,
  SET_AUTH_ERROR_TEXT,
  SET_IS_LOADING,
  SET_LOGGED,
} from "./authActions";

export const setIsLoggedAC = (isLogged) => ({ type: SET_LOGGED, isLogged });
export const setIsLoadingAC = (isLoading) => ({
  type: SET_IS_LOADING,
  isLoading,
});
export const setAuthErrorText = (text) => ({ type: SET_AUTH_ERROR_TEXT, text });
export const setAccessLevelAC = (level) => ({ type: SET_ACCESS_LEVEL, level });

let tokenTimer = null;

export const login = (login, password) => async (dispatch) => {
  await dispatch(setIsLoadingAC(true));
  let res;
  try {
    res = await AuthAPI.auth(login, password);
  } catch (e) {
    //await dispatch(setErrorText(e.message));
  }

  if (!res || (res.statusCode && res.statusCode !== 200)) {
    if (res?.statusCode !== 403) {
      await dispatch(setIsLoggedAC(false));
    } else {
      await dispatch(setAuthErrorText(res?.message));
      await dispatch(setIsLoadingAC(false));
      await dispatch(setIsLoggedAC(false));
    }
  } else {
    const {
      accessToken,
      tokenExpirationDate,
      firstName,
      surname,
      patronymic,
      username,
      roles,
    } = res;
    localStorage.setItem(
      LOCAL_STORAGE_DATA_NAME,
      JSON.stringify({
        accessToken,
        tokenExpirationDate,
        firstName,
        surname,
        patronymic,
        username,
        roles,
      })
    );

    await dispatch(setUserAC({ firstName, surname, patronymic, roles }));
    await dispatch(setAccessLevelAC(getAccessLevel(roles)));
    await dispatch(getSelectedLine());
    await dispatch(setIsLoggedAC(true));
    tokenTimer = new TokenTimer(res?.tokenExpirationDate);
    tokenTimer.startTimer();
  }
  await dispatch(setIsLoadingAC(false));
};

export const checkLogin = () => async (dispatch) => {
  console.log("check login fired");
  await dispatch(setIsLoadingAC(true));
  const localStorageData = localStorage.getItem(LOCAL_STORAGE_DATA_NAME)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_DATA_NAME))
    : null;
  if (localStorageData) {
    try {
      const isValidToken = await AuthAPI.checkToken({
        token: localStorageData.accessToken,
      });
      tokenTimer = new TokenTimer(localStorageData?.tokenExpirationDate);
      tokenTimer.startTimer();
      await dispatch(setUserAC(localStorageData));
      await dispatch(setAccessLevelAC(getAccessLevel(localStorageData.roles)));
      await dispatch(setIsLoggedAC(true));
      await dispatch(getSelectedLine(true));
      await dispatch(setIsLoadingAC(false));
    } catch (e) {
      await dispatch(setIsLoadingAC(false));
      console.log("possible error", e);
      //await dispatch(setErrorText(e.message));
    }
  } else {
    await dispatch(setIsLoggedAC(false));
    await dispatch(setIsLoadingAC(false));
  }
};

export const logout = () => async (dispatch) => {
  console.log("in the logout");
  await dispatch(setErrorText(""));
  await dispatch(setAuthErrorText(""));
  await dispatch(setAllLines([]));
  await dispatch(setUserAC(null));
  await dispatch(openSettings(false));
  await dispatch(resetLine());
  await dispatch(setAccessLevelAC(null));
  await dispatch(setIsLoggedAC(false));
  if (tokenTimer) tokenTimer.destroyTimer();
  localStorage.removeItem(LOCAL_STORAGE_DATA_NAME);
};
