import { productLevels } from "../../constants";

export const getSelectedConsignment = (state) => state.line.selectedConsignment;
export const getLineStatus = (state) => state.line.lineStatus;
// ----------------------------------------------------
export const getProductsStats = (state) => state.line.productsStats;
export const getBoxesStats = (state) => state.line.boxesStats;
export const getPalletsStats = (state) => state.line.palletsStats;
export const getLoadingControl = (state) => state.line.loadingControl;
export const getProduction = (state) => state.settings.line.production;
export const getEmulationMode = (state) => state.line.emulationMode;
export const getActivatedLineStopSignal = (state) =>
  state.line.activatedLineStopSignal;
export const getActivatedLineErrorSignal = (state) =>
  state.line.activatedLineErrorSignal;

// -------------------------------------------------------
export const getProductionStats = (state) => state.line.productionStats;
export const getProductionStartTime = (state) => state.line.lineProductionTime;
export const getScannedItemStatus = (state) => state.line.lineScannedItemStatus;

export const getProductsTableItems = (state) =>
  state.line.itemsTable.filter(
    (el) => el.ProductLevel === productLevels.PRODUCT && el.Status === "SUCCESS"
  );
export const getProductsTable = (state) =>
  state.line.itemsTable.filter(
    (el) => el.ProductLevel === productLevels.PRODUCT
  );
export const getBoxesTable = (state) =>
  state.line.itemsTable.filter((el) => el.ProductLevel === productLevels.BOX);
export const getPalletsTable = (state) =>
  state.line.itemsTable.filter(
    (el) => el.ProductLevel === productLevels.PALLET
  );

export const getSetsTableItems = (state) => {
  const rowSets = state.line.itemsTable.filter(
    (el) => el.ProductLevel === productLevels.SET && el.Status === "SUCCESS"
  );
  return rowSets.map((set) => ({ ...set, Code: `набор: ${set.Code}` }));
};

export const getLastUpdateValidProducts = (state) =>
  state.line.lastUpdateValidCounterProducts;
export const getLastUpdateProducts = (state) =>
  state.line.lastUpdateCounterProducts;
export const getLastUpdateBoxes = (state) => state.line.lastUpdateCounterBoxes;
export const getLastUpdatePallets = (state) =>
  state.line.lastUpdateCounterPallets;
export const getPreloadedStats = (state) => state.line.preloadedStats;
export const getIfBoxFormed = (state) => state.line.boxFormed;

export const getIsLineLoading = (state) => state.line.isLineLoading;
