import React from 'react'
import g from '../../../../assets/styles/Main.module.scss'
import s from './SelectingAlert.module.scss'

export const SelectingAlert = ({text, closeText = 'Закрыть', onClose, onSelect, items, selectedItemId}) => {
    return (
        <>
            <div className={g.overlay} onClick={onClose}/>
            <div className={s.selectingAlert}>
                <p className={s.text}>{text}</p>
                <div className={s.items}>
                    {!!items && items?.map(item => {
                        return <button disabled={item.id === selectedItemId}
                                       onClick={() => onSelect(item.id)}
                                       className={s.item}
                                       key={item.id}>{item.name}</button>
                    })}
                </div>
                <button type="button"
                        onClick={onClose}
                        className={s.btn}>{closeText}</button>
            </div>
        </>
    )
}
