import clsx from 'clsx'
import React from 'react';
import s from './Radio.module.scss'

const Radio = ({name, ref, checked, label, value, onChange, id, disabled, styles, status}) => {
    return (
        <div className={clsx(s.radio, disabled && s.disabled, s[status])} style={{...styles}}>
            <input ref={ref} type="radio" name={name} id={id || value} disabled={disabled} checked={checked} onChange={onChange}
                   value={value} className={s.radioInput} style={{}}/>
            <label htmlFor={id || value} className={s.radioLabel}>{label}</label>
        </div>
    );
};

export default Radio;
