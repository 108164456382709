import {combineReducers} from 'redux'
import {aggregationSettingsReducer} from './Aggregation/aggregationSettingsReducer'
import {baseReducer} from './BaseSettings/baseSettingsReducer'
import {lineSettingsReducer} from './Line/lineSettingsReducer'
import {PLCSettingsReducer} from './PLC/PLCSettingsReducer'
import {printingSettingsReducer} from './Printing/printingSettingsReducer'
import {scanningSettingsReducer} from './Scanning/scanningSettingsReducer'
import {weighingScaleSettingsReducer} from './WeighingScale/weighingScaleSettingsReducer'

export const settingsReducer = combineReducers({
    baseSettings: baseReducer,
    line: lineSettingsReducer,
    printing: printingSettingsReducer,
    scanning: scanningSettingsReducer,
    aggregation: aggregationSettingsReducer,
    plc: PLCSettingsReducer,
    weighingScale: weighingScaleSettingsReducer
})
