import React from 'react'
import clsx from 'clsx'
import s from './SettingsPanel.module.scss'

export const SettingsPanel = ({name, children, className = ''}) => {
    return (
        <div className={clsx(s.panel, className)}>
            <span className={s.title}>{name}</span>
            {children}
        </div>
    )
}
