import React from "react";
import Loader from "components/Common/Loader/Loader";

const EmtpyDiv = ({ isLoading, customStyle }) => (
  <div style={{ height: "4rem", display: "flex", ...customStyle }}>
    {isLoading && (
      <Loader
        style={{
          backgroundColor: "transparent",
          marginTop: `${isLoading ? "2rem" : "0"}`,
          position: "relative",
        }}
      />
    )}
  </div>
);

export default EmtpyDiv;
