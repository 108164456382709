import React from 'react';
import s from './SettingsModal.module.scss'
import g from '../../../../assets/styles/Main.module.scss'
import { EquipmentList, useCurrentLineSettings, NestedProperties } from './utils';

const RunningLineSettings = ({ onClose }) => {

  const currentLineSettings = useCurrentLineSettings();

  return (
    <>
      <div className={g.overlay} onClick={onClose} />
      <div className={s.alert}>
        <container className={s.mainContainer}>
          {Object.keys(currentLineSettings).map((section, ind) => {
            return (
              <>
                <section key={ind} className={s.dataSection}>
                  <h1 className={s.sectionTitle}>{section}</h1>
                  {Object.keys(currentLineSettings[section]).map(row => {
                    if (section === 'Настройки линии' && row === 'Подача сигнала на остановку линии:') {
                      return (
                        <NestedProperties
                          row={row}
                          objectOfProperties={currentLineSettings['Настройки линии']['Подача сигнала на остановку линии:']}
                        />
                      )

                    }
                    if (section === 'Настройки нанесения КМ' && row === 'Список принтеров') {
                      if (currentLineSettings['Настройки нанесения КМ']['Тип нанесения'] === 'На линии автоматически') {
                        return (
                          <EquipmentList
                            func={currentLineSettings['Настройки нанесения КМ']['Список принтеров']}
                            row={row}
                          />
                        )
                      }

                    } if (section === 'Настройки сканирования КМ' && row === 'Список сканеров') {
                      return (
                        <EquipmentList
                          func={currentLineSettings['Настройки сканирования КМ']['Список сканеров']}
                          row={row}
                        />
                      )
                    }
                    if (section === 'Настройки агрегации' && row === 'Уровни Агрегации') {
                      return (
                        <EquipmentList
                          func={currentLineSettings['Настройки агрегации']['Уровни Агрегации']}
                          row={row}
                        />
                      )
                    }
                    return (
                      <div className={s.dataRow}>
                        <span>{row}</span> <span className={s.dataCell}>{currentLineSettings[section][row]}</span>
                      </div>
                    )
                  })}
                </section>
              </>
            )
          })}
        </container>
        <button type="button" onClick={onClose} className={s.btn}>
          Закрыть
        </button>
      </div>
    </>
  )
}

export default RunningLineSettings
