import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getConsignmentId } from "../redux/Settings/Line/lineSettingsSelectors";
import { getSelectedConsignment } from "../redux/Line/lineSelectors";
import { getStatsForIncorrectlyStoppedConsigment } from "./functions";
import {
  resetLine,
  setLineStats,
  setPreloadedStats,
} from "../redux/Line/lineActionCreators";
import { getLineStatus } from "../redux/Line/lineSelectors";
import { getLineSettings } from "../redux/Settings/Line/lineSettingsSelectors";

export const useHandleIncorrectlyStoppedLine = (numberPackagesInAggregates) => {
  const consignmentId = useSelector(getConsignmentId);
  const consignment = useSelector(getSelectedConsignment);
  const [preloadedStats, setStats] = useState(null);
  const lineInfo = useSelector(getLineSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!consignmentId && !!lineInfo.status && lineInfo.status !== "RUN")
      setStats(
        getStatsForIncorrectlyStoppedConsigment(
          consignment,
          numberPackagesInAggregates
        )
      );
  }, [consignment, consignmentId, dispatch]);

  useEffect(() => {
    if (
      consignmentId &&
      preloadedStats?.item?.total &&
      !!lineInfo.status &&
      lineInfo.status !== "RUN"
    ) {
      dispatch(setPreloadedStats(preloadedStats));
      for (const key in preloadedStats) {
        if (preloadedStats[key].total) {
          dispatch(setLineStats(preloadedStats[key]));
        }
      }
    } else if (!consignmentId) {
      dispatch(resetLine());
    }
  }, [consignmentId, dispatch, preloadedStats]);
};
