import {
    SET_ALL_WEIGHING_SETTINGS,
    SET_WEIGHING_IS_ACTIVE,
    SET_WEIGHING_PARITY,
    SET_WEIGHING_STOPBITS,
    UPDATE_WEIGHING_SETTINGS
} from './weighingScaleActions'

const initialState = {weighingScale: null}

export const weighingScaleSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_WEIGHING_SETTINGS:
            return {...action.settings}
        case SET_WEIGHING_IS_ACTIVE:
            return {
                ...state,
                active: action.isActive
            }
        case SET_WEIGHING_STOPBITS:
            return {
                ...state, stopBits: +action.stopBits
            }
        case SET_WEIGHING_PARITY:
            return {
                ...state,
                parity: +action.parity
            }
        case UPDATE_WEIGHING_SETTINGS:
            return {
                ...state,
                [action.key]: action.value
            }
        default:
            return state
    }
}

export const setAllWeighingScaleSettings = (settings) => ({type: SET_ALL_WEIGHING_SETTINGS, settings})
export const updateWeighingSettings = (key, value) => ({type: UPDATE_WEIGHING_SETTINGS, key, value})
