import React from "react";
import { useSelector } from "react-redux";
import { getLineSettings } from "../../../../redux/Settings/Line/lineSettingsSelectors";
import { ArrowDown } from "../../../SVG/ArrowDown";
import s from "./SelectLineBtn.module.scss";

export const SelectLineBtn = ({
  allLines,
  lineStatus,
  setIsSelectLineModalShowed,
}) => {
  const line = useSelector(getLineSettings);

  const lineText =
    !!line && Object.keys(line).length > 0
      ? `Линия ${line.name} (№${line.lineNumber})`
      : "Линия не выбрана";

  const openLinesList = () => {
    if (["DISABLED", "STOP"].includes(lineStatus)) {
      setIsSelectLineModalShowed(true);
    }
  };

  return (
    <button className={s.selectLine} onClick={openLinesList}>
      <span className={s.title}>{lineText}</span>
      {!!allLines.length && (
        <span className={s.arrowDown}>
          <ArrowDown />
        </span>
      )}
    </button>
  );
};
