import React from 'react'

export const ArrowDown = () => {
    return (
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.00001 6.5C4.74401 6.5 4.48801 6.402 4.29301 6.207L0.293006 2.207C-0.0979941 1.816 -0.0979941 1.184 0.293006 0.792998C0.684006 0.401998 1.31601 0.401998 1.70701 0.792998L5.01201 4.098L8.30501 0.917998C8.70401 0.534998 9.33501 0.545998 9.71901 0.942998C10.103 1.34 10.092 1.974 9.69501 2.357L5.69501 6.219C5.50001 6.407 5.25001 6.5 5.00001 6.5Z"
                  fill="#F8F8F8"/>
        </svg>
    )
}
