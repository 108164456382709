import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import {
  getFirstLevelNumberPackagesInAggregates,
  getNoFixedNumberPackagesInAggregatesOnFirstLevel,
} from "../../../../../../redux/Settings/Aggregation/aggregationSettingsSelectors";
import { fabricCorobsArray } from "../../../../../../utils/functions";
import { useHandleIncorrectlyStoppedLine } from "../../../../../../utils/customHooks";
import s from "./BoxesContainer.module.scss";
import { getProductsStats } from "../../../../../../redux/Line/lineSelectors";
import { resetLine } from "../../../../../../redux/Line/lineActionCreators";
import { getPreloadedStats } from "../../../../../../redux/Line/lineSelectors";
import { getLineStatus } from "../../../../../../redux/Line/lineSelectors";

//'../redux/Line/lineActionCreators';

const CUB_WIDTH = 15;
const OFSET = 2;
const CUB_BY_ROW = 3;

export const BoxContainer = () => {
  const numberPackagesInAggregates = useSelector(
    getFirstLevelNumberPackagesInAggregates
  );
  const [resultArray, setResultArray] = useState([]);
  const wrapperRef = useRef(null);
  //const productStats = useSelector(getProductsStats);
  const { valid } = useSelector(getProductsStats);
  const lineStatus = useSelector(getLineStatus);
  const preloadedStats = useSelector(getPreloadedStats);
  const dispatch = useDispatch();
  const [validItems, setValidItems] = useState(0);

  useEffect(() => {
    if (
      lineStatus === "DISABLED" &&
      typeof preloadedStats?.item?.total === "number" &&
      typeof numberPackagesInAggregates === "number"
    ) {
      if (typeof valid === "number") {
        setValidItems(valid - (valid % numberPackagesInAggregates));
      }
    } else {
      if (typeof valid === "number") {
        setValidItems(valid);
      }
    }
  }, [
    valid,
    lineStatus,
    numberPackagesInAggregates,
    preloadedStats?.item?.total,
  ]);

  useHandleIncorrectlyStoppedLine(numberPackagesInAggregates);

  const noFixedNumberPackagesInAggregatesOnFirstLevel = useSelector(
    getNoFixedNumberPackagesInAggregatesOnFirstLevel
  );
  const isView =
    numberPackagesInAggregates > 0 && numberPackagesInAggregates <= 150;

  useEffect(() => {
    if (isView && !noFixedNumberPackagesInAggregatesOnFirstLevel) {
      const corobsColByLineWidth = Math.round(
        wrapperRef.current?.offsetWidth /
          ((Math.round(numberPackagesInAggregates / CUB_BY_ROW) + OFSET) *
            CUB_WIDTH)
      ); //number of boxes that can fit inside the line(div)

      const fullCorobsColByCodes = Math.trunc(
        validItems / numberPackagesInAggregates
      ); //full nunmber of boxes

      const notFullCorobCol = validItems % numberPackagesInAggregates;
      // remaining nr of scanned boxes

      const notFullBoxArray = numberPackagesInAggregates
        ? new Array(numberPackagesInAggregates).fill(false)
        : [];
      for (let i = 0; i < notFullCorobCol; i++) notFullBoxArray[i] = true;

      if (corobsColByLineWidth <= fullCorobsColByCodes) {
        setResultArray(
          fabricCorobsArray(
            corobsColByLineWidth,
            notFullBoxArray,
            numberPackagesInAggregates
          )
        );
      } else {
        setResultArray(
          fabricCorobsArray(
            fullCorobsColByCodes,
            notFullBoxArray,
            numberPackagesInAggregates
          )
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validItems, numberPackagesInAggregates, isView]);

  useEffect(() => {
    return () => dispatch(resetLine());
  }, []);

  return (
    <>
      {isView && (
        <div className={s.progress}>
          <div
            className={s.progressWrapper}
            ref={wrapperRef}
            style={{
              height: noFixedNumberPackagesInAggregatesOnFirstLevel
                ? "4.25rem"
                : "initial",
            }}
          >
            {!noFixedNumberPackagesInAggregatesOnFirstLevel &&
              resultArray.map((boxes, i) => (
                <div className={s.box} key={`boxes-${i}`}>
                  {boxes.map((box, j) => (
                    <span
                      className={clsx(s.place, box && s.filled)}
                      key={`box-${j}`}
                    />
                  ))}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};
