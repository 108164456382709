import React from 'react';
import { v4 as uuidv4 } from 'uuid';


const RootElement = () => {
    const randomPart = uuidv4();
    const dataInSessionStorage = window.sessionStorage.getItem("X-MARK-SESSION-ID");
    if (!dataInSessionStorage) {
        window.sessionStorage.setItem("X-MARK-SESSION-ID", randomPart);
    }


    return (
        <></>
    )
}

export default RootElement