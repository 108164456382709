const numberInputs = [
  "port",
  "lineStopSignalTimeLength",
  "heartBeatPort",
  "heartBeatTimeLength",
  "heartBeatInterval",
  ...generateFieldNames(),
];

export function genValidObjs(PLC) {
  const validationObj = {};
  const fields = generateFieldNames();
  fields.forEach((field) => {
    validationObj[field] = PLC[field];
  });
  return validationObj;
}

export function generateFieldNames() {
  const fieldNames = [];
  const portNums = ["1", "2", "3", "4", "5", "6", "7", "8"];
  portNums.forEach((pnum) => {
    const fields = [`rejectPort${pnum}`, `rejectSignalTimeLength${pnum}`];
    fieldNames.push(...fields);
  });
  return fieldNames;
}

export const changeValue = (name, value) => {
  if (numberInputs.includes(name)) return Number(value);
  return value;
};

export const getNewRejectionSignalField = (plc, PLCErrors) => {
  const portNums = ["1", "2", "3", "4", "5", "6", "7", "8"];
  const fieldsObjs = [];
  portNums.forEach((pnum) => {
    const commonRejStr = `rejectPort${pnum}`;
    const commonSigStr = `rejectSignalTimeLength${pnum}`;
    const portObj = {
      title: `Адрес отбраковки ${pnum}`,
      value: plc[commonRejStr],
      error: PLCErrors.has(commonRejStr),
      name: commonRejStr,
    };
    const signalObj = {
      title: `Длительность сигнала отбраковки ${pnum} (мс)`,
      value: plc[commonSigStr],
      error: PLCErrors.has(commonSigStr),
      name: commonSigStr,
    };
    fieldsObjs.push(portObj, signalObj);
  });

  return fieldsObjs;
};
