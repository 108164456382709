import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import EmtpyDiv from "./EmptyDiv";
import Confirm from "components/Common/Alerts/Confirm/Confirm";
import { getReleaseIntallStatus } from "../appSelectors";
import { useDispatch } from "react-redux";
import { checkLogin } from "redux/Auth/authActionCreators";
import { getIsNewRelease } from "../appSelectors";

const AutoUpdate = ({ isLogged, showAlert, setShowAlert }) => {
  const isNewRelease = useSelector(getIsNewRelease);
  const releaseIntallationStatus = useSelector(getReleaseIntallStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNewRelease) {
      setShowAlert(false);
    }
  }, [isNewRelease]);

  useEffect(() => {
    if (
      (localStorage.getItem("UPDATE_STATUS") === "STARTED" ||
        localStorage.getItem("UPDATE_STATUS") === "PAGE_REFRESHED") &&
      !isLogged
    ) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [localStorage.getItem("UPDATE_STATUS")]);

  useEffect(() => {
    if (
      releaseIntallationStatus === 14 ||
      releaseIntallationStatus === 15 ||
      releaseIntallationStatus === 16 ||
      releaseIntallationStatus === 9
    ) {
      if (!isLogged) {
        dispatch(checkLogin());
      }
    }
  }, [releaseIntallationStatus]);

  return (
    <>
      {showAlert && (
        <Confirm
          text={
            "Идет установка новой версии ПО. Во избежание ошибок программного обеспечения, не выключайте оборудование в процессе установки обновления, не перезагружайте страницу и не закрывайте браузер!"
          }
          onClose={() => {
            return;
          }}
          isLoading={true}
        >
          <EmtpyDiv
            isLoading={true}
            customStyle={{
              height: "5rem",
              marginTop: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </Confirm>
      )}
    </>
  );
};

export default AutoUpdate;
