import { returnState } from '../../../functions'
import { 
    SET_AGGREGATION_LEVEL, 
    SET_ALL_SETTINGS, 
    SET_LOADING, 
    SET_TYPE_LINE, 
    SET_IS_CLIENT, 
    SET_SHOULD_SHOW_DEMO, 
    } from './baseSettingsActions'

const initialState = {
    loading: false,
    aggregationLevel: 1,
    typeLine: "SIMPLE",
    shouldShowDemo: false,
}

export const baseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return returnState(state, action, 'loading', 'isLoading')
        case SET_ALL_SETTINGS:
            return {
                ...state,
                line: action.settings
            }
        case SET_AGGREGATION_LEVEL:
            return {
                ...state,
                aggregationLevel: action.aggregationLevel
            }

        case SET_TYPE_LINE:
            return {
                ...state,
                typeLine: action.payload
            }
        case SET_IS_CLIENT:
            return {
                ...state,
                isClient: action.payload
            }
        case SET_SHOULD_SHOW_DEMO:
            return {
                ...state,
                shouldShowDemo: action.payload
            }
        default:
            return state
    }
}
