import React, { memo, useState } from "react";
import s from "./version-info.module.scss";
import { getInstalledRelVersion } from "redux/App/appSelectors";
import { useSelector } from "react-redux";
import Confirm from "components/Common/Alerts/Confirm/Confirm";
import VersionModal from "./InfoModal";

const VersionInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const version = useSelector(getInstalledRelVersion);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  return (
    <div className={s.swVersionContaier}>
      <span className={s.version}>{`v. ${version}`}</span>
      <div className={s.infoLink} onClick={openModal}>
        Что нового?
      </div>
      {showModal && (
        <Confirm
          text={""}
          onClose={closeModal}
          isLoading={false}
          disagreeText={"Понятно"}
          onDisagree={closeModal}
        >
          <VersionModal version={version} />
        </Confirm>
      )}
    </div>
  );
};

export default VersionInfo;
