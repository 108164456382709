export const getStatsForIncorrectlyStoppedConsigment = (
  incorrectlyStoppedConsigment,
  numberPackagesInAggregates
) => {
  const initialStat = { item: {}, box: {}, pallet: {} };
  if (incorrectlyStoppedConsigment?.productScanCount) {
    const initialItemsTotal =
      incorrectlyStoppedConsigment.productValidScanCount;
    const remained =
      numberPackagesInAggregates === 0
        ? 0
        : initialItemsTotal % numberPackagesInAggregates;
    initialStat.item.total = incorrectlyStoppedConsigment.productScanCount;

    initialStat.item.notValid =
      incorrectlyStoppedConsigment.productDuplicateScanCount +
      incorrectlyStoppedConsigment.productScanInvalidGtinCount +
      incorrectlyStoppedConsigment.productScanInvalidLengthCount +
      incorrectlyStoppedConsigment.productScanNoFoundCount;
    // initialStat.item.valid = remained === 0 ? initialItemsTotal : initialItemsTotal - remained;
    initialStat.item.valid = initialItemsTotal;
    initialStat.item.error =
      incorrectlyStoppedConsigment.productNoReadScanCount;
    initialStat.item.dropout = incorrectlyStoppedConsigment.productDropoutCount;
    initialStat.item.level = 0;
  }
  if (incorrectlyStoppedConsigment?.agregation1ScanCount) {
    initialStat.box.total = incorrectlyStoppedConsigment.agregation1ScanCount;
    initialStat.box.notValid =
      incorrectlyStoppedConsigment.agregation1ScanInvalid;
    initialStat.box.valid = incorrectlyStoppedConsigment.agregation1ScanValid;
    initialStat.box.error = incorrectlyStoppedConsigment.agregation1ScanNoRead;
    initialStat.box.level = 1;
  }
  if (incorrectlyStoppedConsigment?.agregation2ScanCount) {
    initialStat.pallet.total =
      incorrectlyStoppedConsigment.agregation2ScanCount;
    initialStat.pallet.notValid =
      incorrectlyStoppedConsigment.agregation2ScanInvalid;
    initialStat.pallet.valid =
      incorrectlyStoppedConsigment.agregation2ScanValid;
    initialStat.pallet.level = 2;
  }
  return initialStat;
};

export const fabricCorobsArray = (
  leng,
  firscorob,
  numberPackagesInAggregates
) => {
  const corobArray = numberPackagesInAggregates
    ? new Array(numberPackagesInAggregates).fill(true)
    : [];
  const resultArray = [...new Array(leng).fill(corobArray), firscorob];

  return resultArray;
};
