import {changeMultiScannerType} from '../Aggregation/aggregationSettingsActionCreators'
import {
    ADD_SCANNER,
    CHANGE_SCANNER_MODE,
    CHANGE_SCANNER_TYPE,
    DELETE_SCANNER,
    SET_ALL_SCANNING_SETTINGS, UPDATE_SCANNER_DATA
} from './scanningSettingsActions'

export const setAllScanningSettings = (settings) => ({type: SET_ALL_SCANNING_SETTINGS, settings})
export const addScanner = (scannerType, newScanner) => ({type: ADD_SCANNER, scannerType, newScanner})
export const deleteScanner = (scannerID, scannerType) => ({type: DELETE_SCANNER, scannerID, scannerType})
export const changeScannerTypeAC = (scannerType) => ({type: CHANGE_SCANNER_TYPE, scannerType})
export const changeScannerMode = (scannerID, scannerMode) => ({type: CHANGE_SCANNER_MODE, scannerID, scannerMode})
export const updateScannerData = (updatedScanner, scannerType) => ({
    type: UPDATE_SCANNER_DATA,
    updatedScanner,
    scannerType
})

export const changeScannerType = (scannerType, aggregationLevel) => async (dispatch) => {
    await dispatch(changeScannerTypeAC(scannerType))
    await dispatch(changeMultiScannerType(scannerType, aggregationLevel))

}
