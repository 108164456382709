import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingsAPI from "../../../../api/SettingsAPI";
import {
  addErrorControl,
  deleteErrorControl,
  getDateFromTimestamp,
  validate,
} from "../../../../functions";
import { useChangingTimer } from "../../../../hooks";
import { getAccessLevel } from "../../../../redux/Auth/authSelectors";
import {
  changeSelectedConsignment,
  changeSelectedProduct,
  setIsAddMarkingAC,
  setIsConsignment,
  setLineSettings,
  setUpdatedProducts,
} from "../../../../redux/Settings/Line/lineSettingsActionCreators";
import {
  getIsAddMarking,
  getIsConsignment,
  getLineSettings,
} from "../../../../redux/Settings/Line/lineSettingsSelectors";
import Checkbox from "../../../Common/Checkbox/Checkbox";
import Input from "../../../Common/Input/Input";
import { SelectComponent } from "../../../Common/Select/Select";
import { SettingsPanel } from "../../../Common/SettingsPanel/SettingPanel";
import s from "./LineSettingsPanel.module.scss";
import panel from "../../../Common/SettingsPanel/SettingsPanel.module.scss";
import { getTypeLine } from "../../../../redux/Settings/BaseSettings/baseSettingsSelectors";
import { changeTypeLine } from "../../../../redux/Settings/BaseSettings/baseSettingsActionCreators";

function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

const optionsLine = [
  {
    id: "SIMPLE",
    name: "Обычная",
  },
  {
    id: "UNIVERSAL",
    name: "Универсальная",
  },
  {
    id: "AUTOMATHPLCAGR",
    name: "Автоматическая с агрегацией ПЛК",
  },
  {
    id: "PREPRINTSTANTION",
    name: "Станция предпечати",
  },
];

const LineSettingsPanel = ({ setChanged, setErrors, errors }) => {
  const dispatch = useDispatch();
  const line = useSelector(getLineSettings);
  const [lineErrors, setLineErrors] = useState(new Set());
  const [products, setProducts] = useState([]);
  const [consignments, setConsignments] = useState([]);
  const typeLine = useSelector(getTypeLine);
  const accessLevel = useSelector(getAccessLevel);
  const isConsignment = useSelector(getIsConsignment);
  const isAddMarking = useSelector(getIsAddMarking);

  console.log("isConsignment", isConsignment);

  const {
    allowedNumberErrors,
    timeInterval,
    numberPackagesPerHour,
    production,
    validationTime,
  } = line;

  useChangingTimer(setChanged);

  useEffect(() => {
    const prod = production?.products ? production?.products : [];
    if (prod) {
      setProducts([{ id: 0, name: "Не выбрано" }, ...prod]);
    }
  }, [production]);

  useEffect(() => {
    if (line) {
      getConsignments(line.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // return () => {
    //   if (productId.current) {
    //     dispatch(changeSelectedProduct(productId.current));
    //   }
    // };
  }, []);

  useEffect(() => {
    if (lineErrors.size > 0) {
      addErrorControl("line", errors, setErrors);
    } else {
      deleteErrorControl("line", errors, setErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineErrors]);

  useEffect(() => {
    if (accessLevel === "FULL") {
      validate(
        { allowedNumberErrors, timeInterval, numberPackagesPerHour },
        setLineErrors
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessLevel]);

  const changeProductionType = () => {
    dispatch(setIsConsignment(!isConsignment));
    dispatch(changeSelectedProduct(null));
  };

  const handleChangeAddMarking = () => {
    dispatch(setIsAddMarkingAC(!isAddMarking));
  };

  const getConsignments = async (lineId) => {
    const updatedProducts = await SettingsAPI.getUpdatedProducts(lineId);
    dispatch(setUpdatedProducts(updatedProducts));
    const res = await SettingsAPI.getConsignmentsByLineId(lineId);

    if (isIterable(res))
      setConsignments([{ id: 0, name: "Не выбрано" }, ...res]);
  };

  const onChange = (e, key) => {
    setChanged(true);
    dispatch(setLineSettings(+e.target.value || "", key));
  };

  const _changeSelectedProduct = (type, id) => {
    if (type === "product") {
      if (+id === 0) {
        dispatch(changeSelectedProduct(null));
      } else {
        dispatch(changeSelectedProduct(+id));
      }
    } else {
      const findedConsignment = consignments.find((item) => item.id === +id);
      dispatch(changeSelectedProduct(findedConsignment.productId));
      dispatch(changeSelectedConsignment(+id));
    }

    setChanged(true);
  };

  const handleChangeTypeLine = (id) => {
    dispatch(changeTypeLine(id));
    setChanged(true);
  };

  return (
    <SettingsPanel name="Линия" className={s.leftPanel}>
      <div className={s.line}>
        {!!consignments.length && (
          <Checkbox
            id={"changeProductType"}
            label={"Работа по сменным заданиям"}
            styles={{ marginBottom: "1rem" }}
            onChange={() => {
              changeProductionType();
              setChanged(true);
            }}
            name={"changeProductType"}
            checked={isConsignment}
          />
        )}
        {!!consignments?.length && (
          <>
            <SelectComponent
              title="Задания"
              name="consignments"
              disabled={!isConsignment}
              selectedValue={
                isConsignment ? line.consignmentId : consignments[0].id
              }
              onChange={(id) => {
                _changeSelectedProduct("consignment", id);
              }}
              options={consignments.map((item) =>
                item.id === 0
                  ? { id: item.id, name: item.name }
                  : {
                      id: item.id,
                      name: `${item.taskNumber} (${
                        item.productName
                      }) ${getDateFromTimestamp(item.productCreationDate, {
                        delimiter: ".",
                      })} ${item.actualValue}`,
                    }
              )}
            />
          </>
        )}
        {!!products?.length && (
          <>
            <SelectComponent
              title="Продукция"
              name="production"
              disabled={isConsignment}
              selectedValue={
                isConsignment ? products[0].id : production?.selectedProductId
              }
              onChange={(id) => {
                _changeSelectedProduct("product", id);
              }}
              options={products.map((item) => ({
                id: item.id,
                name: item.name,
              }))}
            />
          </>
        )}
        <Input
          title="Скорость линии (упаковок в час)"
          value={numberPackagesPerHour}
          name="speed"
          type="number"
          error={lineErrors.has("numberPackagesPerHour")}
          onChange={(e) => onChange(e, "numberPackagesPerHour")}
        />
        <div className={panel.range}>
          <span className={panel.subtitle}>
            Подача сигнала на остановку линии
          </span>
          <Input
            subtitle="более"
            value={allowedNumberErrors}
            error={lineErrors.has("allowedNumberErrors")}
            name="signal-more"
            type="number"
            onChange={(e) => onChange(e, "allowedNumberErrors")}
          />
          <Input
            subtitle="в течение"
            value={timeInterval}
            error={lineErrors.has("timeInterval")}
            name="signal-duration"
            type="number"
            onChange={(e) => onChange(e, "timeInterval")}
          />
        </div>

        <SelectComponent
          title="Тип линии"
          name="type"
          selectedValue={typeLine}
          onChange={handleChangeTypeLine}
          options={optionsLine}
        />

        <Input
          title="Время валидации кода маркировки (мс)"
          value={validationTime}
          error={lineErrors.has("validationTime")}
          name="validationTime"
          type="number"
          onChange={(e) => onChange(e, "validationTime")}
        />

        <Checkbox
          id={"bindingMarkingCodesToBatchWhenPrinting"}
          label={"Добавление в отчет при печати"}
          styles={{ marginBottom: "1rem" }}
          onChange={() => {
            handleChangeAddMarking();
            setChanged(true);
          }}
          name={"bindingMarkingCodesToBatchWhenPrinting"}
          checked={isAddMarking}
        />
      </div>
    </SettingsPanel>
  );
};

export default LineSettingsPanel;
