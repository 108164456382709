import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import appReducer from "./App/appReducer";
import authReducer from "./Auth/authReducer";
import lineReducer from "./Line/lineReducer";
import { settingsReducer } from "./Settings/settingsReducer";

let rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  line: lineReducer,
  settings: settingsReducer,
});

const composeEnhancers = compose;
// window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//   trace: true,
//   traceLimit: 25,
// });

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
