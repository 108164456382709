import React, {useState} from 'react';
import Control from "../Control/Control";
import HelpInstruction from "./HelpPanels/HelpInstruction";
import './Help.scss'
import HelpVideo from "./HelpPanels/HelpVideo";

let controls = [{name: 'back', title: 'Назад', icon: require('../../assets/img/icons/back.png')},
    {name: 'help', title: 'Помощь'},
    {name: 'videoHelp', title: 'Видеоинструкция'},
    {name: 'callHelp', title: 'Вызвать помощь', icon: require('../../assets/img/icons/callhelp.png')},
];

const Help = ({setIsOpenedHelp}) => {
    let [openTab, setOpenTab] = useState('help');

    return (
        <div className="wrapper help">
            <div className="content">
                <aside className="controls controls_help">
                    {controls.map((item, i) => {
                        return <Control key={i}
                                        icon={item.icon} text={item.title} name={item.name}
                                        openTab={openTab}
                                        setOpenTab={setOpenTab}
                                        toggleWindow={setIsOpenedHelp}
                                        active={openTab === item.name}/>
                    })}
                </aside>
                <div className="info info_panels">
                    {openTab === 'help' && <HelpInstruction/>}
                    {openTab === 'videoHelp' && <HelpVideo/>}
                </div>
            </div>
        </div>
    );
};

export default Help;
