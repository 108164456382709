import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addErrorControl,
  deleteErrorControl,
  validate,
} from "../../../../functions";
import { useChangingTimer } from "../../../../hooks";
import { updatePLCSettings } from "../../../../redux/Settings/PLC/PLCSettingsActionCreators";
import { getPLCSettings } from "../../../../redux/Settings/PLC/PLCSettingsSelectors";
import Input from "../../../Common/Input/Input";
import { ToggleCheckbox } from "../../../Common/ToggleCheckbox/ToggleCheckbox";
import "../../../Common/SettingsPanel/SettingsPanel.module.scss";
import { SettingsPanel } from "../../../Common/SettingsPanel/SettingPanel";
import panel from "../../../Common/SettingsPanel/SettingsPanel.module.scss";
import {
  changeValue,
  getNewRejectionSignalField,
  genValidObjs,
} from "./PLCSettings.constants";
import s from "./PLCSettingsPanel.module.scss";

const PLCSettingsPanel = ({ errors, setErrors, setChanged }) => {
  const dispatch = useDispatch();
  const PLC = useSelector(getPLCSettings);

  const {
    ipAddress,
    port,
    rejectPort1,
    rejectPort2,
    rejectPort3,
    rejectPort4,
    rejectSignalTimeLength1,
    rejectSignalTimeLength2,
    rejectSignalTimeLength3,
    rejectSignalTimeLength4,
    lineStopPort,
    lineStopSignalTimeLength,
    firstLevelAggregationButtonInput,
    consumerPackagingCounterInput,
    startConveyorBeltButtonInput,
    toggleConveyorBeltPort,
    active,
    heartBeatPort,
    heartBeatTimeLength,
    heartBeatInterval,
  } = PLC;

  const [PLCErrors, setPLCErrors] = useState(new Set());

  // useChangingTimer(setChanged);

  useEffect(() => {
    if (PLCErrors.size > 0) {
      addErrorControl("plc", errors, setErrors);
    } else {
      deleteErrorControl("plc", errors, setErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PLCErrors]);

  const changePLCData = (e) => {
    setChanged(true);
    dispatch(
      updatePLCSettings(
        e.target.name,
        changeValue(e.target.name, e.target.value)
      )
    );
  };

  useEffect(() => {
    validate(
      {
        ipAddress,
        port,
      },
      setPLCErrors
    );

    validate(genValidObjs(PLC), setPLCErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PLC]);

  const toggleIsActive = () => {
    dispatch(updatePLCSettings("active", !active));
    setChanged(true);
  };

  return (
    <SettingsPanel name="Программируемый логический контроллер">
      <div className={panel.range}>
        <div className={panel.range} style={{ marginBottom: "1.2rem" }}>
          <ToggleCheckbox
            label={"Активен"}
            checked={active}
            onChange={toggleIsActive}
          />
        </div>

        <Input
          title="IP-адрес"
          value={ipAddress}
          masked={true}
          error={PLCErrors.has("ipAddress")}
          name="ipAddress"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />
        <Input
          title="Порт"
          error={PLCErrors.has("port")}
          value={port}
          name="port"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />
      </div>
      <div className={panel.range}>
        {/* <Input
          title="Адрес отбраковки 1"
          value={rejectPort}
          error={PLCErrors.has("rejectPort")}
          name="rejectPort"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />
        <Input
          title="Адрес отбраковки 2"
          value={rejectPort2}
          error={PLCErrors.has("rejectPort2")}
          name="rejectPort2"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />
        <Input
          title="Адрес отбраковки 3"
          value={rejectPort3}
          error={PLCErrors.has("rejectPort3")}
          name="rejectPort3"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />

        <Input
          title="Адрес отбраковки 4"
          value={rejectPort4}
          error={PLCErrors.has("rejectPort4")}
          name="rejectPort4"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        /> */}
        <div className={panel.range}>
          {getNewRejectionSignalField(PLC, PLCErrors).map((field) => (
            <Input
              title={field.title}
              value={field.value}
              error={field.error}
              name={field.name}
              containerStyles={s.inputW}
              inputStyles={s.inputInputW}
              onChange={(e) => changePLCData(e)}
            />
          ))}
        </div>
      </div>
      <div className={panel.range}>
        {/* <Input
          title="Длительность сигнала отбраковки (мс)"
          value={rejectSignalTimeLength}
          error={PLCErrors.has("rejectSignalTimeLength")}
          name="rejectSignalTimeLength"
          inputStyles={s.inputInputW}
          containerStyles={s.inputW}
          onChange={(e) => changePLCData(e)}
        />

        <Input
          title="Длительность сигнала отбраковки 2 (мс)"
          value={rejectSignalTimeLength2}
          error={PLCErrors.has("rejectSignalTimeLength2")}
          name="rejectSignalTimeLength2"
          inputStyles={s.inputInputW}
          containerStyles={s.inputW}
          onChange={(e) => changePLCData(e)}
        />

        <Input
          title="Длительность сигнала отбраковки 3 (мс)"
          value={rejectSignalTimeLength3}
          error={PLCErrors.has("rejectSignalTimeLength3")}
          name="rejectSignalTimeLength3"
          inputStyles={s.inputInputW}
          containerStyles={s.inputW}
          onChange={(e) => changePLCData(e)}
        />

        <Input
          title="Длительность сигнала отбраковки 4 (мс)"
          value={rejectSignalTimeLength4}
          error={PLCErrors.has("rejectSignalTimeLength4")}
          name="rejectSignalTimeLength4"
          inputStyles={s.inputInputW}
          containerStyles={s.inputW}
          onChange={(e) => changePLCData(e)}
        /> */}

        <Input
          title="Адрес остановки линии"
          value={lineStopPort}
          error={PLCErrors.has("lineStopPort")}
          name="lineStopPort"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />

        <Input
          title="Длительность сигнала остановки линии"
          value={lineStopSignalTimeLength}
          error={PLCErrors.has("lineStopSignalTimeLength")}
          name="lineStopSignalTimeLength"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />
      </div>

      <div className={panel.range}>
        <Input
          title="Адрес кнопки агрегации короба"
          value={firstLevelAggregationButtonInput}
          error={PLCErrors.has("firstLevelAggregationButtonInput")}
          name="firstLevelAggregationButtonInput"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
          styles={{ width: "70%" }}
        />
      </div>

      <div className={panel.range}>
        <Input
          title="Адрес счетчика потребительской упаковки"
          value={consumerPackagingCounterInput}
          error={PLCErrors.has("consumerPackagingCounterInput")}
          name="consumerPackagingCounterInput"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />
      </div>

      <div className={panel.range}>
        <Input
          title="Адрес кнопки запуска конвейера"
          value={startConveyorBeltButtonInput}
          error={PLCErrors.has("startConveyorBeltButtonInput")}
          name="startConveyorBeltButtonInput"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />

        <Input
          title="Адрес переключения состояния конвейера"
          value={toggleConveyorBeltPort}
          error={PLCErrors.has("toggleConveyorBeltPort")}
          name="toggleConveyorBeltPort"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />

        <Input
          title="Выход сигнала Heartbeat"
          value={heartBeatPort}
          error={PLCErrors.has("heartBeatPort")}
          name="heartBeatPort"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />

        <Input
          title="Длительность сигнала Heartbeat в мс"
          value={heartBeatTimeLength}
          error={PLCErrors.has("heartBeatTimeLength")}
          name="heartBeatTimeLength"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />

        <Input
          title="Интервал между сигналами Heartbeat в мс"
          value={heartBeatInterval}
          error={PLCErrors.has("heartBeatInterval")}
          name="heartBeatInterval"
          containerStyles={s.inputW}
          inputStyles={s.inputInputW}
          onChange={(e) => changePLCData(e)}
        />
      </div>

      <div className={panel.range}>
        <p className={panel.content}>
          <h4>ВНИМАНИЕ! Инструкция по настройке:</h4>
          Для Simence LOGO!
          <ul>
            <li>Значения input начинаются с 0 (т.е. I1 = 0, I2 = 1, и т.д.)</li>
            <li>Значения output начинаются с 8192 и т.д.</li>
          </ul>
          Для остальных версий ПЛК описание будет пополняться.
        </p>
      </div>
    </SettingsPanel>
  );
};

export default PLCSettingsPanel;
