import {
    ADD_APPLICATION_PRINTER,
    ADD_APPLICATION_SCANNER,
    ADD_MULTISCANNER,
    CHANGE_AGGREGATION_TYPE,
    CHANGE_APPLICATION_SCANNER_MODE,
    CHANGE_APPLICATION_TYPE,
    CHANGE_MULTISCANNER_MODE,
    CHANGE_MULTISCANNING_TYPE,
    DELETE_APPLICATION_PRINTER,
    DELETE_APPLICATION_SCANNER,
    DELETE_MULTISCANNER,
    SET_All_AGGREGATION_SETTINGS,
    SET_FLOAT_NUMBER_PACKAGES,
    UPDATE_APPLICATION_PRINTER_DATA,
    UPDATE_APPLICATION_SCANNER_DATA,
    UPDATE_MULTISCANNER_DATA,
    UPDATE_NUMBER_PACKAGES
} from './aggregationSettingsActions'

export const setAllAggregationSettings = (settings) => ({type: SET_All_AGGREGATION_SETTINGS, settings})
export const updateNumberPackages = (numberPackagesInAggregates, aggregationLevel) => ({
    type: UPDATE_NUMBER_PACKAGES,
    numberPackagesInAggregates,
    aggregationLevel
})
export const setFloatNumberPackages = (isFloat, aggregationLevel) => ({
    type: SET_FLOAT_NUMBER_PACKAGES,
    isFloat,
    aggregationLevel
})
export const addMultiScanner = (scannerType, newScanner, aggregationLevel) => ({
    type: ADD_MULTISCANNER,
    scannerType,
    newScanner,
    aggregationLevel
})
export const addApplicationPrinter = (newPrinter, aggregationLevel) => ({
    type: ADD_APPLICATION_PRINTER,
    newPrinter,
    aggregationLevel
})
export const addApplicationScanner = (newScanner, aggregationLevel) => ({
    type: ADD_APPLICATION_SCANNER,
    newScanner,
    aggregationLevel
})
export const deleteMultiScanner = (scannerID, scannerType, aggregationLevel) => ({
    type: DELETE_MULTISCANNER,
    scannerID,
    scannerType,
    aggregationLevel
})
export const deleteApplicationPrinter = (printerID, applicationType, aggregationLevel) => ({
    type: DELETE_APPLICATION_PRINTER,
    printerID,
    applicationType,
    aggregationLevel
})
export const deleteApplicationScanner = (scannerID, applicationType, aggregationLevel) => ({
    type: DELETE_APPLICATION_SCANNER,
    scannerID,
    applicationType, aggregationLevel
})
export const updateMultiScannerData = (updatedScanner, scannerType, aggregationLevel) => ({
    type: UPDATE_MULTISCANNER_DATA,
    updatedScanner,
    scannerType, aggregationLevel
})
export const updateApplicationPrinterData = (updatedPrinter, applicationType, aggregationLevel) => ({
    type: UPDATE_APPLICATION_PRINTER_DATA,
    updatedPrinter,
    applicationType, aggregationLevel
})
export const updateApplicationScannerData = (updatedScanner, applicationType, aggregationLevel) => ({
    type: UPDATE_APPLICATION_SCANNER_DATA,
    updatedScanner,
    applicationType, aggregationLevel
})
export const changeAggregationType = (aggregationType, aggregationLevel) => ({
    type: CHANGE_AGGREGATION_TYPE,
    aggregationType,
    aggregationLevel
})
export const changeApplicationType = (applicationType, aggregationLevel) => ({
    type: CHANGE_APPLICATION_TYPE,
    applicationType,
    aggregationLevel
})
export const changeMultiScannerMode = (scannerID, scannerMode, aggregationLevel) => ({
    type: CHANGE_MULTISCANNER_MODE,
    scannerID,
    scannerMode, aggregationLevel
})
export const changeApplicationScannerMode = (scannerID, scannerMode, aggregationLevel) => ({
    type: CHANGE_APPLICATION_SCANNER_MODE,
    scannerID,
    scannerMode, aggregationLevel
})

export const changeMultiScannerType = (scanningType, aggregationLevel) => ({
    type: CHANGE_MULTISCANNING_TYPE,
    scanningType, aggregationLevel
})
