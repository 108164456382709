import clsx from 'clsx'
import React from 'react'
import s from './ToggleCheckbox.module.scss'

export const ToggleCheckbox = ({label, checked, onChange, containerStyles}) => {
    return (
        <div className={clsx(s.toggle, containerStyles)}>
            <span className={s.toggleTitle}>{label}</span>
            <button type="button" className={clsx(s.button, s.buttonR, s.button1)} onClick={onChange} >
                <input type="checkbox" name="active" className={s.checkbox} checked={checked || false} onChange={onChange}/>
                <span className={s.knobs}/>
                <span className={clsx(s.layer)} />
            </button>
        </div>
    )
}
