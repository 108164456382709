import React from "react";
import { useSelector } from "react-redux";
import s from "./SettingsModal.module.scss";
import { printingModeList } from "../../../../constants";
import { getSettings } from "../../../../redux/Settings/BaseSettings/baseSettingsSelectors";
import { getSelectedConsignment } from "../../../../redux/Line/lineSelectors";

export const lineTypes = {
  SIMPLE: "Обычная",
  UNIVERSAL: "Универсальная",
};

export const scanningMode = {
  AUTO: "Автоматическая",
  SEMIAUTO: "Полу-автоматическая",
  MANUALLY: "Вручную",
};

export const aggregationType = {
  MATH: "Математическая",
  TECHNICAL: "Техническая",
  TECHSIMPLIFIED: "Техническая (Упрощённая)",
};

export const scanningTypes = {
  AUTO: "Автоматические сканеры",
  MANUALLY: "Ручные сканеры",
  TERMINAL: "ТСД",
};

export const NestedEquipmentList = ({ func, row, style = s.nestedLine }) => {
  const list = func();
  return (
    <section className={s.printersSection}>
      <h1 className={s.printersSectionTitle}>
        {row !== "Уровни Агрегации" && row}
      </h1>
      {list.map((printer) => {
        return (
          <>
            <span className={style} />
            {Object.keys(printer).map((printerProperty) => {
              return (
                <div className={s.dataRow}>
                  <span>{printerProperty}</span>{" "}
                  <span className={s.dataCell}>{printer[printerProperty]}</span>
                </div>
              );
            })}
          </>
        );
      })}
    </section>
  );
};

export const NestedProperties = ({ row, objectOfProperties }) => {
  return (
    <>
      <div className={s.dataRow}>
        <span>{row}</span>
      </div>

      {Object.keys(objectOfProperties).map((key) => {
        return (
          <div className={s.dataRow}>
            <span className={s.dataCellIndented}>{key}</span>{" "}
            <span className={s.dataCell}>{objectOfProperties[key]}</span>
          </div>
        );
      })}
    </>
  );
};

export const EquipmentList = ({ func, row, style = s.line }) => {
  const list = func();
  return (
    <section className={s.printersSection}>
      <h1 className={s.printersSectionTitle}>
        {row !== "Уровни Агрегации" && row}
      </h1>
      {list.map((printer) => {
        return (
          <>
            <span className={style} />
            {Object.keys(printer).map((printerProperty) => {
              if (typeof printer[printerProperty] === "function") {
                return (
                  <NestedEquipmentList
                    func={printer[printerProperty]}
                    row={printerProperty}
                  />
                );
              }
              return (
                <div className={s.dataRow}>
                  <span>{printerProperty}</span>{" "}
                  <span className={s.dataCell}>{printer[printerProperty]}</span>
                </div>
              );
            })}
          </>
        );
      })}
    </section>
  );
};

const printerInfo = (printer) => {
  return {
    Название: printer.name,
    "IP-Адрес": printer.ipAddress,
    Порт: printer.port,
    Статус: printer.active ? "Активен" : "Неактивен",
  };
};

const scannerInfo = (scanner) => {
  return {
    Название: scanner.name,
    "IP-Адрес": scanner.ipAddress,
    Порт: scanner.port,
    "Тип подключения": scanner.mode,
    Статус: scanner.active ? "Активен" : "Неактивен",
  };
};

const getProductName = (product) => {
  const productArray = product.split(" ");
  return productArray[0].trim();
};

function printEquipment(equipmentList, equipmentInfo) {
  return equipmentList.map((equipment) => {
    return equipmentInfo(equipment);
  });
}

export const useCurrentLineSettings = () => {
  const settingsFromBack = useSelector(getSettings);
  const productInfo = useSelector((state) => state.line.productInfo);

  return {
    "Настройки линии": {
      "Работа по сменному заданию": settingsFromBack.line.consignmentStatus
        ? "да"
        : "нет",
      "Название задания": productInfo.partNumber ?? "нет данных",
      Продукция: productInfo.productName ?? "нет данных",
      "Скорость линии": settingsFromBack.line.numberPackagesPerHour,
      "Подача сигнала на остановку линии:": {
        Более: settingsFromBack.line.allowedNumberErrors,
        "В течении": settingsFromBack.line.timeInterval,
      },
      "Тип линии": lineTypes[settingsFromBack.line.type],
      "Добавление в отчёт при печати":
        settingsFromBack.line.bindingMarkingCodesToBatchWhenPrinting ??
        "нет данных",
    },
    "Настройки нанесения КМ": {
      "Тип нанесения": printingModeList[settingsFromBack.printing.type],
      "Список принтеров":
        printingModeList[settingsFromBack.printing.type] ===
        "На линии автоматически"
          ? printEquipment.bind(
              null,
              settingsFromBack.printing.printers,
              printerInfo
            )
          : "нет данных",
    },
    "Настройки сканирования КМ": {
      "Тип сканирования": scanningTypes[settingsFromBack.scanning.type],
      "Список сканеров": printEquipment.bind(
        null,
        settingsFromBack.scanning.scanners,
        scannerInfo
      ),
    },
    "Настройки агрегации": {
      // 'Кол-во упаковок в перв.агрегате': settingsFromBack.aggregation[0].numberPackagesInAggregates,
      "Уровни Агрегации": function () {
        return settingsFromBack.aggregation.map((aggregationLevel, ind) => {
          return {
            "Уровень агрегации": ind + 1,
            "Кол-во упаковок в агрегате":
              aggregationLevel.numberPackagesInAggregates,
            "Тип агрегации": aggregationType[aggregationLevel.type],
            "Нанесение и сканирование кодов агрегации":
              scanningMode[aggregationLevel.application.type],
            "Список принтер-аппликаторов":
              aggregationLevel.application.type !== "MANUALLY"
                ? printEquipment.bind(
                    null,
                    aggregationLevel.application.printers,
                    printerInfo
                  )
                : "нет данных",
            "Список одиночных сканнеров":
              aggregationLevel.type === "TECHNICAL" &&
              aggregationLevel.application.type === "AUTO"
                ? printEquipment.bind(
                    null,
                    aggregationLevel.application.scanners,
                    scannerInfo
                  )
                : "нет данных",
            "Список групповых сканнеров":
              aggregationLevel.type === "TECHNICAL"
                ? printEquipment.bind(
                    null,
                    aggregationLevel.multiScanning.scanners,
                    scannerInfo
                  )
                : "нет данных",
          };
        });
      },
    },
    "Настройки ПЛК": {
      "IP-адрес": settingsFromBack.plc.ipAddress,
      Порт: settingsFromBack.plc.port,
      "Адрес отбраковки 1": settingsFromBack.plc.rejectPort,
      "Адрес отбраковки 2": settingsFromBack.plc.rejectPort2,
      "Адрес отбраковки 3": settingsFromBack.plc.rejectPort3,
      "Длительность сигнала отбраковки (мс)":
        settingsFromBack.plc.rejectSignalTimeLength,
      "Длительность сигнала отбраковки 2 (мс)":
        settingsFromBack.plc.rejectSignalTimeLength2,
      "Длительность сигнала остановки линии":
        settingsFromBack.plc.lineStopSignalTimeLength,
      "Адрес остановки линии": settingsFromBack.plc.lineStopPort,
      "Адрес кнопки агрегации короба":
        settingsFromBack.plc.firstLevelAggregationButtonInput,
      "Адрес счетчика потребительской упаковки":
        settingsFromBack.plc.consumerPackagingCounterInput,
      "Адрес кнопки запуска конвейера":
        settingsFromBack.plc.startConveyorBeltButtonInput,
      "Адрес переключения состояния конвейера":
        settingsFromBack.plc.toggleConveyorBeltPort,
      Статус: settingsFromBack.plc.active ? "Активен" : "Неактивен",
    },
    "Настройки весов": {
      "Адрес устройства": settingsFromBack.weighingScale.address,
      "Название устройства": settingsFromBack.weighingScale.name,
      "COM-порт": settingsFromBack.weighingScale.connectionString,
      "Количество битов": settingsFromBack.weighingScale.dataBits,
      "Скорость обмена данными (бод/с)":
        settingsFromBack.weighingScale.baudrate,
      Статус: settingsFromBack.weighingScale.active ? "Активен" : "Неактивен",
      "Бит чётности/нечётности": settingsFromBack.weighingScale.parity,
      "Количество стоповых битов": settingsFromBack.weighingScale.stopBits,
    },
  };
};
