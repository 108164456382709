import React from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { AggregateBtn } from './components/AggregateBtn/AggregateBtn'
import { BoxContainer } from './components/BoxesContainer'
import { getNoFixedNumberPackagesInAggregatesOnFirstLevel, } from '../../../../redux/Settings/Aggregation/aggregationSettingsSelectors'
import { getLineStatus } from '../../../../redux/Line/lineSelectors'

import s from './AggregationView.module.scss'

export const AggregationView = React.memo(() => {
    const noFixedNumberPackagesInAggregatesOnFirstLevel = useSelector(getNoFixedNumberPackagesInAggregatesOnFirstLevel)
    const lineStatus = useSelector(getLineStatus)

    return <div className={s.visualization}>
        <div className={clsx(s.progressContainer, noFixedNumberPackagesInAggregatesOnFirstLevel && s.emptyContainer)}>
            {lineStatus === 'PAUSE' && <div className={s.pause}>Пауза/пуско-наладка</div>}
            <BoxContainer />
        </div>
        {noFixedNumberPackagesInAggregatesOnFirstLevel && <AggregateBtn />}
    </div>
})