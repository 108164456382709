import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkDevices, deleteErrorControl } from "../../../../functions";
import { useChangingTimer } from "../../../../hooks";
import { setFloatNumberPackages } from "../../../../redux/Settings/Aggregation/aggregationSettingsActionCreators";
import { getAggregationLevel } from "../../../../redux/Settings/BaseSettings/baseSettingsSelectors";
import {
  addScanner,
  changeScannerMode,
  changeScannerType,
  deleteScanner,
  updateScannerData,
} from "../../../../redux/Settings/Scanning/scanningSettingsActionCreators";
import {
  getScanners,
  getScanningType,
} from "../../../../redux/Settings/Scanning/scanningSettingsSelectors";
import Radio from "../../../Common/Radio/Radio";
import { SettingsPanel } from "../../../Common/SettingsPanel/SettingPanel";
import AddScannerControl from "../../components/ScannerControl/AddScannerControl";
import panel from "../../../Common/SettingsPanel/SettingsPanel.module.scss";
import ScannerControl from "../../components/ScannerControl/ScannerControl";

const ScanningSettingsPanel = ({
  setErrors,
  errors,
  setChanged,
  validateDevice,
}) => {
  const dispatch = useDispatch();
  const scanningType = useSelector(getScanningType);
  const scanners = useSelector(getScanners);
  const aggregationLevel = useSelector(getAggregationLevel);
  const [selectedType, setSelectedType] = useState(scanningType);

  // useChangingTimer(setChanged)

  useEffect(() => {
    if (checkDevices(scanners)) {
      deleteErrorControl("scanning", errors, setErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanners]);

  useEffect(() => {
    if (scanningType === "TERMINAL") {
      deleteErrorControl("scanning", errors, setErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanningType]);

  const onChangeScanningType = (e) => {
    const value = e.target.value;
    setSelectedType(value);
    dispatch(changeScannerType(value, aggregationLevel));

    if (value === "AUTO" || value === "TERMINAL") {
      dispatch(setFloatNumberPackages(false, aggregationLevel));
    }

    setChanged(true);
  };

  const removeScanner = (id) => {
    dispatch(deleteScanner(id, selectedType));
    setChanged(true);
  };

  const changeDeviceData = (scanner) => {
    dispatch(updateScannerData(scanner, selectedType));
    setChanged(true);
  };

  const addNewScanner = () => {
    let newScanner = {
      id: Date.now(),
      name: "",
      ipAddress: "",
      port: "",
      active: false,
      mode: "CLIENT",
    };
    dispatch(addScanner(selectedType, newScanner));
    setChanged(true);
  };

  return (
    <SettingsPanel name="Сканирование">
      <div className={panel.panelRadio}>
        <span className={panel.subtitle}>Тип сканирования</span>
        <Radio
          checked={scanningType === "AUTO"}
          label="Автоматические сканеры"
          name="scanning-type"
          value="AUTO"
          onChange={onChangeScanningType}
        />
        <Radio
          checked={scanningType === "MANUALLY"}
          label="Ручные сканеры"
          name="scanning-type"
          value="MANUALLY"
          onChange={onChangeScanningType}
        />
        <Radio
          checked={scanningType === "TERMINAL"}
          label="ТСД"
          name="scanning-type"
          value="TERMINAL"
          onChange={onChangeScanningType}
        />
      </div>
      {selectedType !== "TERMINAL" && (
        <>
          <div>
            <span className={panel.subtitle}>Сканер</span>
            {scanners?.map((item) => (
              <ScannerControl
                device={item}
                id={item.id}
                key={item.id}
                errors={errors}
                validateDevice={validateDevice}
                setErrors={setErrors}
                panelName="scanning"
                removeDevice={removeScanner}
                changeDeviceData={changeDeviceData}
                changeScannerMode={changeScannerMode}
                isScanner={true}
              />
            ))}
          </div>
          <AddScannerControl type={scanningType} addNew={addNewScanner} />
        </>
      )}
    </SettingsPanel>
  );
};

export default ScanningSettingsPanel;
