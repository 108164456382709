import React, { useEffect, useState } from "react";
import clsx from "clsx";
import st from "./AutoUpdate.module.scss";
import releaseApi from "api/ReleaseApi";
import { getReleaseIntallStatus } from "redux/App/appSelectors";
import { useSelector } from "react-redux";

let interval;

const startingTime = 30;

const CountDownModal = ({ hideModal, onStartLoader }) => {
  const [secondsLeft, setSecondsLeft] = useState(startingTime);
  const [showButton, setShowButton] = useState(true);
  const releaseIntallationStatus = useSelector(getReleaseIntallStatus);

  const startLoader = () => {
    clearInterval(interval);
    interval = "canceled";
    onStartLoader();
  };

  const onDismiss = () => {
    console.log("onDissmiss fired");
    clearInterval(interval);
    interval = "canceled";
    releaseApi.delayUpdate();
    hideModal();
  };

  useEffect(() => {
    if (secondsLeft === 3) {
      setShowButton(false);
    }
    if (secondsLeft === startingTime) {
      interval = setInterval(() => setSecondsLeft((p) => p - 1), 1000);
    }
    if (secondsLeft === 1) {
      clearInterval(interval);
      if (releaseIntallationStatus === 6 || releaseIntallationStatus === 5) {
        console.log("from secondsLelf===1");
        startLoader();
      }
    }
  }, [secondsLeft]);

  return (
    <main className={st.mainCountDownWrapper}>
      <span>{`Обновление начнется через ${secondsLeft} секунд`}</span>
      {showButton && (
        <button type="button" onClick={onDismiss} className={st.btn}>
          {"Отмена"}
        </button>
      )}
    </main>
  );
};

export default CountDownModal;
