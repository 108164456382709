export const getAggregation = state => state.settings.aggregation
export const getAggregationType = state => state.settings.aggregation[state.settings.baseSettings.aggregationLevel - 1]?.type
export const getApplicationType = state => state.settings.aggregation[state.settings.baseSettings.aggregationLevel - 1]?.application?.type
export const getMultiScanningScanners = state => state.settings.aggregation[state.settings.baseSettings.aggregationLevel - 1]?.multiScanning?.scanners
export const getApplicationPrinters = state => state.settings.aggregation[state.settings.baseSettings.aggregationLevel - 1]?.application?.printers
export const getApplicationScanners = state => state.settings.aggregation[state.settings.baseSettings.aggregationLevel - 1]?.application?.scanners
export const getNumberPackagesInAggregatesLv1 = state => state.settings.aggregation[0]?.numberPackagesInAggregates
export const getNumberPackagesInAggregatesLv2 = state => state.settings.aggregation[1]?.numberPackagesInAggregates
export const getFirstLevelNumberPackagesInAggregates = state => state.settings.aggregation[0]?.numberPackagesInAggregates
export const getNoFixedNumberPackagesInAggregates = state => state.settings.aggregation[state.settings.baseSettings.aggregationLevel - 1]?.noFixedNumberPackagesInAggregates
export const getNoFixedNumberPackagesInAggregatesOnFirstLevel = state => state.settings.aggregation[0]?.noFixedNumberPackagesInAggregates;