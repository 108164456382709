import React from 'react';
import s from './Confirm.module.scss';

const CalendarIcon = () => (
<svg className={s.calenderIcon} width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="1.14282" y1="7.94287" x2="23.4285" y2="7.94287" stroke="#5589FF" stroke-width="2"/>
    <rect x="1" y="2.97137" width="22" height="22.6857" stroke="#5589FF" stroke-width="2"/>
    <line x1="7.85718" y1="0.599976" x2="7.85718" y2="4.71426" stroke="#5589FF" stroke-width="2"/>
    <line x1="17.4573" y1="0.599976" x2="17.4573" y2="4.71426" stroke="#5589FF" stroke-width="2"/>
    <rect x="4.80005" y="10.8856" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="8.91431" y="10.8856" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="13.0286" y="10.8856" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="17.1428" y="10.8856" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="4.80005" y="14.3143" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="8.91431" y="14.3143" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="13.0286" y="14.3143" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="17.1428" y="14.3143" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="4.80005" y="17.7428" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="8.91431" y="17.7428" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="13.0286" y="17.7428" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="17.1428" y="17.7428" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="4.80005" y="21.1715" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="8.91431" y="21.1715" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="13.0286" y="21.1715" width="2.05714" height="2.05714" fill="#5589FF"/>
    <rect x="17.1428" y="21.1715" width="2.05714" height="2.05714" fill="#5589FF"/>
</svg>
);

export default CalendarIcon