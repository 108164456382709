import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteIcon } from "../../../../constants";
import { addErrorControl, deleteErrorControl } from "../../../../functions";
import { getAggregationLevel } from "../../../../redux/Settings/BaseSettings/baseSettingsSelectors";
import Input from "../../../Common/Input/Input";
import Radio from "../../../Common/Radio/Radio";
import { ToggleCheckbox } from "../../../Common/ToggleCheckbox/ToggleCheckbox";
import s from "./ScannerControl.module.scss";

const ScannerControl = ({
  device,
  removeDevice,
  isScanner,
  changeDeviceData,
  changeScannerMode,
  setErrors,
  errors,
  panelName,
  validateDevice,
}) => {
  const dispatch = useDispatch();
  const aggregationLevel = useSelector(getAggregationLevel);

  let { id, name, ipAddress, port, active, mode, stage } = device;
  let [_isActive, setIsActive] = useState(null);
  let [deviceErrors, setDeviceErrors] = useState(new Set());

  useEffect(() => {
    setIsActive(device.active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (device) {
      validateDevice(device, setDeviceErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  useEffect(() => {
    if (deviceErrors.size > 0) {
      switch (panelName) {
        case "scanning":
          addErrorControl("scanning", errors, setErrors);
          break;
        case "printing":
          addErrorControl("printing", errors, setErrors);
          break;
        case "aggregation":
          addErrorControl("aggregation", errors, setErrors);
          break;
        default:
          break;
      }
    } else {
      switch (panelName) {
        case "scanning":
          deleteErrorControl("scanning", errors, setErrors);
          break;
        case "printing":
          deleteErrorControl("printing", errors, setErrors);
          break;
        case "aggregation":
          deleteErrorControl("aggregation", errors, setErrors);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceErrors]);

  useEffect(() => {
    changeValue(null, _isActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_isActive]);

  // useEffect(() => {
  //     if (mode === 'CLIENT') {
  //         changeValue({ target: { name: 'ipAddress', value: '0.0.0.0' } })
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mode])

  const changeValue = (e, checkboxVal) => {
    let val = null;

    if (e) {
      switch (e.target.name) {
        case "port":
          val = +e.target.value || "";
          break;
        case "stage":
          val = e.target.value || "";
          break;
        default:
          val = e.target.value;
      }
    } else {
      val = checkboxVal;
    }
    let updatedDevice = null;
    if (isScanner) {
      updatedDevice = {
        id,
        name,
        ipAddress,
        port,
        active,
        stage,
        [e ? e.target.name : "active"]: val,
        mode,
      };
    } else {
      updatedDevice = {
        id,
        name,
        ipAddress,
        port,
        active,
        stage,
        [e ? e.target.name : "active"]: val,
      };
    }

    changeDeviceData(updatedDevice);
  };

  const changeMode = (e) => {
    dispatch(changeScannerMode(id, e.target.value, aggregationLevel));
  };

  return (
    <div className={s.scanner}>
      <div className={s.scannerInputs}>
        <Input
          title="Название"
          name="name"
          value={name}
          onChange={changeValue}
          error={deviceErrors.has("name")}
          inputStyles={s.inputInput}
          containerStyles={s.name}
        />
        <Input
          title="IP-адрес"
          disabled={mode === "CLIENT"}
          name="ipAddress"
          value={ipAddress}
          onChange={changeValue}
          error={deviceErrors.has("ipAddress")}
          inputStyles={s.inputInput}
          containerStyles={s.ip}
        />
        <Input
          title="Порт"
          name="port"
          value={port}
          onChange={changeValue}
          error={deviceErrors.has("port")}
          inputStyles={s.inputInput}
          containerStyles={s.port}
        />
        <Input
          title="Этап"
          name="stage"
          value={stage}
          onChange={changeValue}
          error={deviceErrors.has("stage")}
          inputStyles={s.inputInput}
          containerStyles={s.stage}
        />
      </div>
      <div className={s.scannerControls}>
        <ToggleCheckbox
          label={"Активен"}
          checked={_isActive}
          onChange={() => setIsActive((prev) => !prev)}
          containerStyles={s.checkbox}
        />
        {isScanner && (
          <>
            <Radio
              checked={mode === "CLIENT"}
              id={`client${id}${Date.now()}`}
              onChange={changeMode}
              styles={{ marginBottom: 0, alignSelf: "center" }}
              label="Клиент"
              name={`${name}${mode}${id}${Date.now()}`}
              value="CLIENT"
            />
            <Radio
              checked={mode === "SERVER"}
              id={`server${id}`}
              onChange={changeMode}
              label="Сервер"
              name={name}
              styles={{ marginBottom: 0 }}
              value="SERVER"
            />
          </>
        )}
        <button
          type="button"
          onClick={() => {
            removeDevice(id);
          }}
        >
          <img src={deleteIcon} alt="Удалить сканер" className={s.deleteBtn} />
        </button>
      </div>
    </div>
  );
};

export default React.memo(ScannerControl);
