import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import Loader from './components/Common/Loader/Loader';
export const useAlert = (_isAlertShowed = false) => {
    const [alertText, setAlertText] = useState('')
    const [isAlertShowed, setIsAlertShowed] = useState(_isAlertShowed)

    const showAlert = (text) => {
        setAlertText(text)
        setIsAlertShowed(true)
    }

    const hideAlert = () => {
        setIsAlertShowed(false)
    }

    return {setAlertText, alertText, isAlertShowed, showAlert, hideAlert}
}

export const useChangingTimer = (setChanged, ms=100) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            setChanged(false)
            clearTimeout(timer)
        }, ms)

        return () => {
            clearTimeout(timer)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
}

export const useModal = () => {
    const [isShowed, setIsShowed] = useState(true)

    const hideModal = () => setIsShowed(false)
    const showModal = () => setIsShowed(true)

    return {isShowed, hideModal, showModal}
}

export const useDisablePreloader = () => {
    const loadState = useSelector(state => state.line.loadingControl);
    const [active, setActive] = useState(true);
    const [message, setMessage] = useState('');
    const toogle = () => {
        setActive(prevActive => !prevActive)
    }
    const open = () => {
        setActive(true)
    } 
    const close = () => {
        setActive(false)
    }
    const realMessage = () => {
        switch(loadState){
            case 'RUN':
                setMessage('Подождите, линия запускается...')
                break;
            case 'STOP':
                setMessage('Подождите, линия останавливается...')
                break;
            case 'PAUSE':
                setMessage('Подождите, ставим линию на паузу...')
                break;
            default:
                break
        }
    }
    useEffect(() => {
        if(loadState){
            open()
            realMessage()
        }else{
            close()
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadState])

    const behaviorStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000,
        height: '100%',
        background: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'collumn',
    }

    const LoaderStyle = {
        position: 'inherit',
        background: 'inherit'
    }
    // eslint-disable-next-line no-unused-expressions
    const View = () => (<>{active && <div style={behaviorStyle}><div><Loader style={LoaderStyle}/></div><div>{message}</div></div>}</>)

    return {open, close, toogle, active, message, View, loadState }
}