import API from "./APIService";

class AuthAPI {
  //   auth = (username, password) =>
  //     API.post("/api/auth/tokenDebug", {
  //       username,
  //       password,
  //       api: "operator.up-line",
  //     });
  auth = (username, password) =>
    API.post("/api/auth/token/", {
      username,
      password,
      api: "operator.up-line",
    });
  checkToken = (payload) => API.post("/api/auth/CheckTokenLifeTime", payload);
  // refreshToken = () => API.get("/api/auth/refreshTokenDebug");
  refreshToken = () => API.get("/api/auth/refreshToken");
}

export default new AuthAPI();
