import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import s from './Input.module.scss';


const Input = ({ title, name, value, subtitle, onChange, type, error, disabled, containerStyles, inputStyles, min, max, isDefault = true }) => {


    return (
        <div className={clsx({
            [s.input]: isDefault,
            [`${s.input}${name}`]: true,
            [containerStyles]: true
        })}>
            {!subtitle && isDefault ? <span className={s.title}>{title}</span> :
                isDefault ? <span className={s.subtitle}>{subtitle}</span> : ''}
            <input type={type} disabled={disabled} name={name} value={value} placeholder={title}
                className=
                {clsx({
                    [s.inputInput]: isDefault,
                    ['error']: error,
                    [inputStyles]: true
                })}
                onChange={onChange}
                min={min} max={max}
            />
        </div>
    )
}

export default Input
