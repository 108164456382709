import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getProductionStats, getPreloadedStats } from '../../../../../../redux/Line/lineSelectors'
import s from './ProductionStats.module.scss'
import { getIsAggregationIncluded } from '../../../../../../redux/App/appSelectors'


export const ProductionStats = ({ isConsignment }) => {
    const { products, boxes, pallets, totalWeight } = useSelector(getProductionStats)

    const preloadedstats = useSelector(getPreloadedStats)

    const isAggregationIncluded = useSelector(getIsAggregationIncluded);

    const [productsToShow, setProducts] = useState(products)
    const [boxesToShow, setBoxes] = useState(boxes)
    const [palletsToShow, setPallets] = useState(pallets)

    useEffect(() => {
        setProducts(products + preloadedstats?.item?.valid)
        setBoxes(boxes + (preloadedstats.box?.valid || 0))
        setPallets(pallets + (preloadedstats?.pallet?.valid || 0))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preloadedstats, products, boxes, pallets])

    return (
        <div className={clsx(s.production, !isConsignment && s.large)}>
            <div>
                <div className={s.row}>
                    <span className={s.key}>Ед. продукции:</span>
                    <span className={s.value}>{`${productsToShow} шт`}</span>
                </div>
                {isAggregationIncluded &&
                    <>
                        <div className={s.row}>
                            <span className={s.key}>Короба</span>
                            <span className={s.value}>{`${boxesToShow} шт`}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.key}>Паллеты:</span>
                            <span className={s.value}>{`${palletsToShow} шт`}</span>
                        </div>
                    </>
                }
                <div className={s.row}>
                    <span className={s.key}>Общий вес:</span>
                    <span className={s.value}>{`${totalWeight} кг`}</span>
                </div>
            </div>
        </div>
    )
}
