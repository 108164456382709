import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LineAPI from '../../../../../../api/LineAPI'
import { setAppLoading } from '../../../../../../redux/App/appActionCreators'
import {
    getLastUpdateProducts,
    getLineStatus,
    getProductsStats,
    getProductsTableItems,
    getLastUpdateBoxes,
    getLastUpdateValidProducts,
    getIfBoxFormed
} from '../../../../../../redux/Line/lineSelectors'
import { getApplicationType } from '../../../../../../redux/Settings/Aggregation/aggregationSettingsSelectors'
import { getScanningType } from '../../../../../../redux/Settings/Scanning/scanningSettingsSelectors'
import { Alert } from '../../../../../Common/Alerts/Alert/Alert'
import s from './AggregateBtn.module.scss'
import { getLineScannedItemDropoutStatus, getLineScannedItemSuccessStatus, setBoxFormed } from '../../../../../../redux/Line/lineActionCreators'

export const AggregateBtn = () => {
    const dispatch = useDispatch()
    const loadState = useSelector(state => state.line.loadingControl);
    const applicationType = useSelector(getApplicationType)
    const scanningType = useSelector(getScanningType)
    const lineStatus = useSelector(getLineStatus)
    const isDropout = useSelector(state => state.line.isDropout);
    const isSuccess = useSelector(state => state.line.isSuccess);
    const validBoxes = useSelector(state => state.line.boxesStats.valid)
    // const isBoxFormed = useSelector(getIfBoxFormed);

    const [inAggregateAmount, setInAggregateAmount] = useState(0)
    const [isAggregationAlertShowed, setIsAggregationAlertShowed] = useState(false)



    useEffect(() => {
        if (isSuccess) {
            setInAggregateAmount(prev => prev + 1);
            dispatch(getLineScannedItemSuccessStatus(false));
        }
    }, [isSuccess])



    useEffect(() => {
        setInAggregateAmount(0);
    }, [validBoxes])

    useEffect(() => {

        if (isDropout && inAggregateAmount > 0) {
            setInAggregateAmount(prev => prev - 1);
            dispatch(getLineScannedItemDropoutStatus(false));
        }
    }, [isDropout])


    useEffect(() => {
        if (lineStatus === 'STOP' || loadState === "RUN" || lineStatus === 'DISABLED') {
            setInAggregateAmount(0)
        }
    }, [lineStatus, loadState]);


    const onCreateAggregateBtnClick = () => {
        if (applicationType === 'MANUALLY') {
            setIsAggregationAlertShowed(true)
        } else {
            createAggregate()
        }
    }

    const createAggregate = async () => {
        dispatch(setAppLoading(true))
        const res = await LineAPI.createAggregate()

        if (res.statusCode === 200) {
            setInAggregateAmount(0)
        }
        dispatch(setAppLoading(false))
    }

    return (
        <>
            {isAggregationAlertShowed &&
                <Alert text='Отсканируйте код агрегации'
                    closeText={'Отмена'}
                    onClose={() => setIsAggregationAlertShowed(false)} />}

            <button className={s.aggregateBtn} disabled={!inAggregateAmount} onClick={onCreateAggregateBtnClick}>
                <span className={s.scanned}>{inAggregateAmount}</span>
                {scanningType !== 'TERMINAL' && <span className={s.label}>Короб</span>}
            </button>
        </>
    )
}
