import React, {useEffect, useState} from 'react';
import HelpAPI from '../../../api/HelpAPI'

const HelpInstruction = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        HelpAPI.getInstruction().then(res => setContent(res));
    }, []);

    return (
        <div className="panel panel_instruction">
            <h1 className="instruction__title">{!!content && content.title}</h1>
            {!!content && content.content.map((section, i) => {
                return <div key={i} className="instruction__section">
                    <h2 className="instruction__section-title">{section.title}</h2>
                    <img className="instruction__section-pic" src={section.pic} alt=""/>
                    <p className="instruction__section-text">{section.text}</p>
                </div>
            })}
        </div>
    );
};

export default HelpInstruction;
