import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkDevices, deleteErrorControl } from "../../../../functions";
import { useChangingTimer } from "../../../../hooks";
import {
  addPrinter,
  changePrinterType,
  deletePrinter,
  updatePrinterData,
} from "../../../../redux/Settings/Printing/printingSettingsActionCreators";
import {
  getPrinters,
  getPrintingType,
} from "../../../../redux/Settings/Printing/printingSettingsSelectors";
import Radio from "../../../Common/Radio/Radio";
import { SettingsPanel } from "../../../Common/SettingsPanel/SettingPanel";
import AddScannerControl from "../../components/ScannerControl/AddScannerControl";
import panel from "../../../Common/SettingsPanel/SettingsPanel.module.scss";
import ScannerControl from "../../components/ScannerControl/ScannerControl";

const PrintingSettingsPanel = ({
  setErrors,
  errors,
  setChanged,
  validateDevice,
}) => {
  const dispatch = useDispatch();
  const printers = useSelector(getPrinters);
  const printingType = useSelector(getPrintingType);
  const [selectedType, setSelectedType] = useState(printingType);

  // useChangingTimer(setChanged)

  useEffect(() => {
    if (checkDevices(printers)) {
      deleteErrorControl("printing", errors, setErrors);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printers]);

  const onChangePrintingType = (e) => {
    setSelectedType(e.target.value);
    dispatch(changePrinterType(e.target.value));
    setChanged(true);

    if (e.target.value === "TYPOGRAPHY" || e.target.value === "MANUALLY") {
      deleteErrorControl("printing", errors, setErrors);
    }
  };

  const addNewPrinter = () => {
    let newPrinter = {
      id: Date.now(),
      name: "",
      ipAddress: "",
      port: "",
      active: false,
    };
    dispatch(addPrinter(selectedType, newPrinter));
    setChanged(true);
  };

  const removePrinter = (id) => {
    dispatch(deletePrinter(id, selectedType));
    setChanged(true);
  };

  const changeDeviceData = (printer) => {
    dispatch(updatePrinterData(printer, selectedType));
    setChanged(true);
  };

  return (
    <SettingsPanel name="Нанесение">
      {/*<div className="panel panel_printing">*/}
      <div className={panel.panelRadio}>
        <span className={panel.panelSubtitle}>Тип нанесения</span>
        <Radio
          checked={selectedType === "TYPOGRAPHY"}
          label="В типографии"
          name="printing-type"
          value="TYPOGRAPHY"
          onChange={onChangePrintingType}
        />
        <Radio
          checked={selectedType === "AUTO"}
          label="На линии автоматически"
          name="printing-type"
          value="AUTO"
          onChange={onChangePrintingType}
        />
        {/* <Radio checked={selectedType === 'MANUALLY'} label="На линии вручную" name="printing-type"
                       value="MANUALLY" onChange={onChangePrintingType}/> */}
        <Radio
          checked={selectedType === "PREPRINT"}
          label="Предварительная печать"
          name="printing-type"
          value="PREPRINT"
          onChange={onChangePrintingType}
        />
      </div>
      {selectedType === "AUTO" && (
        <>
          <div className={panel.panelScanner}>
            <span className={panel.panelSubtitle}>Принтер</span>
            {printers?.map((item) => (
              <ScannerControl
                errors={errors}
                validateDevice={validateDevice}
                device={item}
                key={item.id}
                panelName="printing"
                removeDevice={removePrinter}
                changeDeviceData={changeDeviceData}
                setErrors={setErrors}
              />
            ))}
          </div>
          <AddScannerControl
            type={selectedType}
            addNew={addNewPrinter}
            title="Добавить принтер +"
          />
        </>
      )}
      {/*</div>*/}
    </SettingsPanel>
  );
};

export default PrintingSettingsPanel;
