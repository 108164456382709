import React from 'react';

const Icon = ({error, style}) => {
    if (error) {
        return (
            <span className="results__row-icon" style={style}>
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5 23C17.3467 23 22 18.0262 22 12C22 5.97382 17.3467 1 11.5 1C5.6533 1 1 5.97382 1 12C1 18.0262 5.6533 23 11.5 23Z"
                    fill="#C50F1F" stroke="#F8F8F8" strokeWidth="2"/>
                <path
                    d="M15.3223 10.8223C15.4863 10.8223 15.6387 10.8535 15.7793 10.916C15.9238 10.9785 16.0488 11.0625 16.1543 11.168C16.2598 11.2734 16.3438 11.3984 16.4062 11.543C16.4688 11.6836 16.5 11.8359 16.5 12C16.5 12.1641 16.4688 12.3184 16.4062 12.4629C16.3438 12.6035 16.2598 12.7266 16.1543 12.832C16.0488 12.9375 15.9238 13.0215 15.7793 13.084C15.6387 13.1465 15.4863 13.1777 15.3223 13.1777H8.31445C8.15039 13.1777 7.99609 13.1465 7.85156 13.084C7.71094 13.0215 7.58789 12.9375 7.48242 12.832C7.37695 12.7266 7.29297 12.6035 7.23047 12.4629C7.17188 12.3184 7.14258 12.1641 7.14258 12C7.14258 11.8359 7.17188 11.6836 7.23047 11.543C7.29297 11.3984 7.37695 11.2734 7.48242 11.168C7.58789 11.0625 7.71094 10.9785 7.85156 10.916C7.99609 10.8535 8.15039 10.8223 8.31445 10.8223H15.3223Z"
                    fill="#F8F8F8"/>
            </svg>
            </span>
        );
    } else {
        return (
            <span className="results__row-icon" style={style}>
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5 23C17.3467 23 22 18.0262 22 12C22 5.97382 17.3467 1 11.5 1C5.6533 1 1 5.97382 1 12C1 18.0262 5.6533 23 11.5 23Z"
                    fill="#6BB700" stroke="#F8F8F8" strokeWidth="2"/>
                <path
                    d="M15.1992 8.56055C15.3633 8.56055 15.5156 8.5918 15.6562 8.6543C15.8008 8.71289 15.9258 8.79492 16.0312 8.90039C16.1367 9.00586 16.2188 9.13086 16.2773 9.27539C16.3398 9.41602 16.3711 9.56836 16.3711 9.73242C16.3711 9.88867 16.3418 10.0391 16.2832 10.1836C16.2246 10.3281 16.1406 10.4551 16.0312 10.5645L11.3789 15.2227C11.2695 15.332 11.1426 15.418 10.998 15.4805C10.8535 15.5391 10.7031 15.5684 10.5469 15.5684C10.3906 15.5684 10.2402 15.5391 10.0957 15.4805C9.95117 15.418 9.82422 15.332 9.71484 15.2227L7.59375 13.1016C7.48438 12.9922 7.39844 12.8652 7.33594 12.7207C7.27734 12.5762 7.24805 12.4258 7.24805 12.2695C7.24805 12.1055 7.2793 11.9531 7.3418 11.8125C7.4043 11.668 7.48828 11.543 7.59375 11.4375C7.69922 11.332 7.82227 11.25 7.96289 11.1914C8.10742 11.1289 8.26172 11.0977 8.42578 11.0977C8.58203 11.0977 8.73242 11.127 8.87695 11.1855C9.02148 11.2441 9.14844 11.3281 9.25781 11.4375L10.5469 12.7266L14.3672 8.90039C14.4766 8.79102 14.6035 8.70703 14.748 8.64844C14.8926 8.58984 15.043 8.56055 15.1992 8.56055Z"
                    fill="#F8F8F8"/>
            </svg>
            </span>
        )
    }
};

export default Icon;
