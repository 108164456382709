import {
    ADD_PRINTER,
    CHANGE_PRINTER_TYPE,
    DELETE_PRINTER,
    SET_ALL_PRINTING_SETTINGS,
    UPDATE_PRINTER_DATA
} from './printingSettingsActions'

export const setAllPrintingSettings = (settings) => ({type: SET_ALL_PRINTING_SETTINGS, settings})
export const addPrinter = (printerType, newPrinter) => ({type: ADD_PRINTER, printerType, newPrinter})
export const deletePrinter = (printerID, printerType) => ({type: DELETE_PRINTER, printerID, printerType})
export const changePrinterType = (printerType) => ({type: CHANGE_PRINTER_TYPE, printerType})
export const updatePrinterData = (updatedPrinter, printerType) => ({
    type: UPDATE_PRINTER_DATA,
    updatedPrinter,
    printerType
})
