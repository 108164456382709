import React from 'react'
import {ConsignmentControlPanel} from './components/ConsignmentControlPanel/ConsignmentControlPanel'
import s from '../../Root.module.scss'
import {ConsignmentPanel} from './components/ConsignmentPanel/ConsignmentPanel'
import {ProductionStats} from './components/ProductionStats/ProductionStats'

export const InfoTable = ({ isConsignment}) => {
    
    return (
        <div className={s.infoData}>
            <ConsignmentPanel isConsignment={isConsignment}/>
            <ProductionStats isConsignment={isConsignment} />
            {isConsignment && <ConsignmentControlPanel/>}
        </div>
    )
}
