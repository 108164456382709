import React from 'react';
import s from './ScannerControl.module.scss'

const AddScannerControl = ({title = "Добавить сканер +", addNew}) => {
    return (
        <button type="button" className={s.addScanner} onClick={addNew}>
            <span className={s.title}>{title}</span>
        </button>
    );
};

export default AddScannerControl;
