import { LOCAL_STORAGE_DATA_NAME } from "../constants";
import AuthAPI from "../api/AuthAPI";

export class TokenTimer {
  constructor(expiredTime) {
    this.time = expiredTime * 1000;
    this.timer = null;
    console.log("expirationTime", expiredTime);
  }

  startTimer() {
    const delay = 5000;
    const expired = Math.floor((this.time - Date.now()) / 1.5);
    const realExpired = expired <= delay ? 0 : expired - delay;

    console.log("real-expiration", realExpired);
    this.timer = setTimeout(async () => {
      this.getNewToken();
    }, realExpired);
  }

  setNewToken(newToken) {
    const localStorageData = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_DATA_NAME)
    );

    localStorageData.accessToken = newToken.accessToken;
    localStorageData.tokenExpirationDate = newToken.tokenExpirationDate;
    localStorage.setItem(
      LOCAL_STORAGE_DATA_NAME,
      JSON.stringify(localStorageData)
    );
    this.time = newToken.tokenExpirationDate * 1000;
  }
  async getNewToken() {
    const newData = await AuthAPI.refreshToken();
    this.setNewToken(newData);
    this.startTimer();
    return newData;
  }

  destroyTimer() {
    clearTimeout(this.timer);
  }
}
