import { SET_LOADING } from "../Settings/BaseSettings/baseSettingsActions";
import {
  OPEN_SETTINGS,
  SET_ALL_LINES,
  SET_DISPLAY_MODE,
  SET_ERROR_TEXT,
  SET_SOCKET_ERROR_TEXT,
  SET_USER,
  IS_AGGREGATION_INCLUDED,
} from "./appActions";
import SettingsAPI from "../../api/SettingsAPI";

export const openSettingsAC = (isOpenedSettings) => ({
  type: OPEN_SETTINGS,
  isOpenedSettings,
});
export const setErrorText = (errorText) => ({
  type: SET_ERROR_TEXT,
  errorText,
});
export const setErrorTextSocket = (errorText) => ({
  type: SET_SOCKET_ERROR_TEXT,
  errorText,
});
export const setUserAC = (user) => ({ type: SET_USER, user });
export const setAllLines = (lines) => ({ type: SET_ALL_LINES, lines });
export const setAppLoading = (isLoading) => ({ type: SET_LOADING, isLoading });
export const setDisplayMode = (mode) => ({ type: SET_DISPLAY_MODE, mode });
const setAggregationIncluded = (isAggregationIncluded) => ({
  type: IS_AGGREGATION_INCLUDED,
  payload: isAggregationIncluded,
});

export const openSettings = (isOpen) => async (dispatch) => {
  await dispatch(openSettingsAC(isOpen));
};

export const getAggregationIncluded = () => async (dispatch) => {
  const isAggregationIncluded = await SettingsAPI.getAggregationIncludedInfo();
  dispatch(setAggregationIncluded(isAggregationIncluded));
};
