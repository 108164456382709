import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkDevices,
  deleteErrorControl,
  validate,
} from "../../../../functions";
import {
  addApplicationPrinter,
  addApplicationScanner,
  addMultiScanner,
  changeAggregationType,
  changeApplicationScannerMode,
  changeApplicationType,
  changeMultiScannerMode,
  deleteApplicationPrinter,
  deleteApplicationScanner,
  deleteMultiScanner,
  setFloatNumberPackages,
  updateApplicationPrinterData,
  updateApplicationScannerData,
  updateMultiScannerData,
  updateNumberPackages,
} from "../../../../redux/Settings/Aggregation/aggregationSettingsActionCreators";
import {
  getAggregation,
  getAggregationType,
  getApplicationPrinters,
  getApplicationScanners,
  getApplicationType,
  getMultiScanningScanners,
  getNoFixedNumberPackagesInAggregates,
  getNumberPackagesInAggregatesLv1,
  getNumberPackagesInAggregatesLv2,
} from "../../../../redux/Settings/Aggregation/aggregationSettingsSelectors";
import { getSelectedConsignment } from "../../../../redux/Line/lineSelectors";
import { changeAggregationLevel } from "../../../../redux/Settings/BaseSettings/baseSettingsActionCreators";
import { getAggregationLevel } from "../../../../redux/Settings/BaseSettings/baseSettingsSelectors";
import { getScanningType } from "../../../../redux/Settings/Scanning/scanningSettingsSelectors";
import { getIsConsignment } from "../../../../redux/Settings/Line/lineSettingsSelectors";

import Checkbox from "../../../Common/Checkbox/Checkbox";
import Input from "../../../Common/Input/Input";
import Radio from "../../../Common/Radio/Radio";
import { SettingsPanel } from "../../../Common/SettingsPanel/SettingPanel";
import AddScannerControl from "../../components/ScannerControl/AddScannerControl";
import s from "./AggregationSettingsPanel.module.scss";
import panel from "../../../Common/SettingsPanel/SettingsPanel.module.scss";
import ScannerControl from "../../components/ScannerControl/ScannerControl";

const AggregationSettingsPanel = ({
  setErrors,
  errors,
  setChanged,
  validateDevice,
}) => {
  const dispatch = useDispatch();
  const selectedCons = useSelector(getSelectedConsignment);
  const [aggregationErrors, setAggregationErrors] = useState(new Set());
  const aggregation = useSelector(getAggregation);
  const applicationType = useSelector(getApplicationType);
  const aggregationType = useSelector(getAggregationType);
  const multiScanningScanners = useSelector(getMultiScanningScanners);
  const numberPackagesInAggregates1 = useSelector(
    getNumberPackagesInAggregatesLv1
  );
  const numberPackagesInAggregates2 = useSelector(
    getNumberPackagesInAggregatesLv2
  );
  const noFixedNumberPackagesInAggregates = useSelector(
    getNoFixedNumberPackagesInAggregates
  );
  const scanningType = useSelector(getScanningType);
  const aggregationLevel = useSelector(getAggregationLevel);
  const applicationPrinters = useSelector(getApplicationPrinters);
  const applicationScanners = useSelector(getApplicationScanners);
  const isConsignment = useSelector(getIsConsignment);
  const [aggregatedQuantityLv1, setAggregatedQuantityLv1] = useState(
    numberPackagesInAggregates1
  );
  const [aggregatedQuantityLv2, setAggregatedQuantityLv2] = useState(
    numberPackagesInAggregates2
  );

  useEffect(() => {
    if (
      checkDevices(multiScanningScanners) &&
      checkDevices(applicationPrinters) &&
      checkDevices(applicationScanners)
    ) {
      deleteErrorControl("aggregation", errors, setErrors);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    multiScanningScanners,
    applicationPrinters,
    applicationScanners,
    aggregationLevel,
  ]);

  // useEffect(() => {
  //   setTimeout(() => setChanged(false), 100);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (selectedCons) {
      if (selectedCons.codesQuantityFirstLevel !== 0) {
        dispatch(updateNumberPackages(selectedCons.codesQuantityFirstLevel, 1));
        dispatch(setFloatNumberPackages(false, 1));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCons]);

  const onChangeAggregationType = (e) => {
    dispatch(changeAggregationType(e.target.value, aggregationLevel));
    setChanged(true);
  };

  const onChangeApplicationType = (e) => {
    dispatch(changeApplicationType(e.target.value, aggregationLevel));
    setChanged(true);
  };

  const addNewMultiScanner = () => {
    let newMultiScanner = {
      id: Date.now(),
      name: "",
      ipAddress: "0.0.0.0",
      port: "",
      active: false,
      mode: "CLIENT",
    };
    dispatch(addMultiScanner(scanningType, newMultiScanner, aggregationLevel));
    setChanged(true);
  };

  const addNewApplicationPrinter = () => {
    let newApplicationPrinter = {
      id: Date.now(),
      name: "",
      ipAddress: "0.0.0.0",
      port: "",
      active: false,
    };
    dispatch(addApplicationPrinter(newApplicationPrinter, aggregationLevel));
    setChanged(true);
  };

  const addNewApplicationScanner = () => {
    let newApplicationScanner = {
      id: Date.now(),
      name: "",
      ipAddress: "0.0.0.0",
      port: "",
      active: false,
      mode: "CLIENT",
    };
    dispatch(addApplicationScanner(newApplicationScanner, aggregationLevel));
    setChanged(true);
  };

  const removeMultiScanner = (id) => {
    dispatch(deleteMultiScanner(id, scanningType, aggregationLevel));
    setChanged(true);
  };

  const removeApplicationPrinter = (id) => {
    dispatch(deleteApplicationPrinter(id, applicationType, aggregationLevel));
    setChanged(true);
  };

  const removeApplicationScanner = (id) => {
    dispatch(deleteApplicationScanner(id, applicationType, aggregationLevel));
    setChanged(true);
  };

  const changeDeviceData = (printer) => {
    dispatch(
      updateMultiScannerData(printer, aggregationType, aggregationLevel)
    );
    setChanged(true);
  };

  const changeApplicationPrinterData = (printer) => {
    dispatch(
      updateApplicationPrinterData(printer, applicationType, aggregationLevel)
    );
    setChanged(true);
  };

  const changeApplicationScannerData = (scanner) => {
    dispatch(
      updateApplicationScannerData(scanner, applicationType, aggregationLevel)
    );
    setChanged(true);
  };

  const changeFloatNumberPackages = () => {
    dispatch(changeAggregationType("MATH", aggregationLevel));
    dispatch(
      setFloatNumberPackages(
        !noFixedNumberPackagesInAggregates,
        aggregationLevel
      )
    );
    setChanged(true);
  };

  useEffect(() => {
    if (aggregationLevel === 1) {
      if (aggregatedQuantityLv1 > 0) {
        validate({ aggregatedQuantityLv1 }, setAggregationErrors);
      }

      dispatch(
        updateNumberPackages(Number(aggregatedQuantityLv1), aggregationLevel)
      );
      setChanged(true);
    } else if (aggregationLevel === 2) {
      if (aggregatedQuantityLv2 > 0) {
        validate({ aggregatedQuantityLv2 }, setAggregationErrors);
      }

      dispatch(
        updateNumberPackages(Number(aggregatedQuantityLv2), aggregationLevel)
      );
      setChanged(true);
    }
  }, [aggregatedQuantityLv1, aggregatedQuantityLv2]);

  const selectedConsignmentDisableValue =
    isConsignment &&
    selectedCons &&
    !!selectedCons.codesQuantityFirstLevel &&
    aggregationLevel === 1;
  return (
    <SettingsPanel name="Агрегация">
      <div className={s.tabs}>
        <span className={s.title}>Уровень агрегации</span>
        {aggregation.map((item, i) => {
          return (
            <button
              key={i}
              onClick={() => dispatch(changeAggregationLevel(i + 1))}
              className={clsx(s.tab, aggregationLevel === i + 1 && s.selected)}
            >
              {i + 1}
            </button>
          );
        })}
      </div>
      <Input
        title="Кол-во упаковок в перв. агрегате"
        value={
          aggregationLevel === 1 ? aggregatedQuantityLv1 : aggregatedQuantityLv2
        }
        error={aggregationErrors.has("numberPackagesInAggregates")}
        type="number"
        name="boxes-amount"
        min={0}
        max={200}
        disabled={
          selectedConsignmentDisableValue || noFixedNumberPackagesInAggregates
        }
        onChange={(e) => {
          aggregationLevel === 1
            ? setAggregatedQuantityLv1(e.target.value)
            : setAggregatedQuantityLv2(e.target.value);
          // dispatch(updateNumberPackages(+e.target.value, aggregationLevel))
          // setChanged(true)
        }}
      />
      <Checkbox
        checked={noFixedNumberPackagesInAggregates}
        label="Переменное"
        name="numberPackagesInAggregates-float"
        disabled={
          selectedConsignmentDisableValue ||
          scanningType === "AUTO" ||
          applicationType === "AUTO"
        }
        id={Date.now()}
        value={noFixedNumberPackagesInAggregates}
        onChange={changeFloatNumberPackages}
      />
      <div className={panel.panelRadio}>
        <span className={panel.subtitle}>Тип агрегации</span>
        <Radio
          checked={aggregationType === "TECHNICAL"}
          label="Техническая"
          name="aggregation-type"
          value="TECHNICAL"
          onChange={onChangeAggregationType}
          disabled={noFixedNumberPackagesInAggregates}
        />
        <Radio
          checked={aggregationType === "MATH"}
          label="Математическая"
          name="aggregation-type"
          value="MATH"
          onChange={onChangeAggregationType}
        />
        <Radio
          checked={aggregationType === "TECHSIMPLIFIED"}
          label="Техническая (Упрощённая)"
          name="aggregation-type"
          value="TECHSIMPLIFIED"
          onChange={onChangeAggregationType}
          disabled={noFixedNumberPackagesInAggregates}
        />
      </div>
      {(aggregationType === "TECHNICAL" ||
        aggregationType === "TECHSIMPLIFIED") && (
        <>
          <div>
            <span className={panel.title}>
              Сканеры для группового сканирования
            </span>
            {multiScanningScanners?.map((item) => (
              <ScannerControl
                errors={errors}
                device={item}
                validateDevice={validateDevice}
                key={item.id}
                isScanner={true}
                panelName="aggregation"
                changeScannerMode={changeMultiScannerMode}
                removeDevice={removeMultiScanner}
                changeDeviceData={changeDeviceData}
                setErrors={setErrors}
              />
            ))}
          </div>
          <AddScannerControl
            type={aggregationType}
            addNew={addNewMultiScanner}
          />
        </>
      )}
      <div className={panel.panelRadio}>
        <span className={panel.subtitle}>
          Нанесение и сканирование кодов агрегации
        </span>
        <Radio
          checked={applicationType === "AUTO"}
          disabled={noFixedNumberPackagesInAggregates}
          label="Автоматическая"
          name="application-type"
          value="AUTO"
          onChange={onChangeApplicationType}
        />
        <Radio
          checked={applicationType === "SEMIAUTO"}
          label="Полу-автоматическая"
          name="application-type"
          value="SEMIAUTO"
          onChange={onChangeApplicationType}
        />
        <Radio
          checked={applicationType === "MANUALLY"}
          label="Вручную"
          name="application-type"
          value="MANUALLY"
          onChange={onChangeApplicationType}
        />
      </div>
      {applicationType !== "MANUALLY" && (
        <>
          <div>
            <span className={panel.subtitle}>
              {applicationType === "AUTO" ? "Принтер-аппликатор" : "Принтер"}
            </span>
            {applicationPrinters?.map((item) => (
              <ScannerControl
                errors={errors}
                device={item}
                key={item.id}
                validateDevice={validateDevice}
                panelName="aggregation"
                removeDevice={removeApplicationPrinter}
                changeDeviceData={changeApplicationPrinterData}
                setErrors={setErrors}
              />
            ))}
          </div>
          <AddScannerControl
            type={applicationType}
            title="Добавить принтер +"
            addNew={addNewApplicationPrinter}
          />
        </>
      )}
      {/* eslint-disable-next-line no-mixed-operators */}
      {applicationType === "MANUALLY" ||
        ((aggregationType === "TECHNICAL" ||
          aggregationType === "TECHSIMPLIFIED") &&
          applicationType === "AUTO" && (
            <>
              <div>
                <span className={panel.subtitle}>Ручной сканер</span>
                {applicationScanners?.map((item) => (
                  <ScannerControl
                    errors={errors}
                    device={item}
                    validateDevice={validateDevice}
                    key={item.id}
                    isScanner={true}
                    panelName="aggregation"
                    changeScannerMode={changeApplicationScannerMode}
                    removeDevice={removeApplicationScanner}
                    changeDeviceData={changeApplicationScannerData}
                    setErrors={setErrors}
                  />
                ))}
              </div>
              <AddScannerControl
                type={applicationType}
                addNew={addNewApplicationScanner}
              />
            </>
          ))}
    </SettingsPanel>
  );
};

export default AggregationSettingsPanel;
