import clsx from "clsx";
import React, { useEffect, useState } from "react";
import g from "../../../../assets/styles/Main.module.scss";
import s from "./Confirm.module.scss";
import LineAPI from "../../../../api/LineAPI";
import { useSelector } from "react-redux";
import { getLineSettings } from "../../../../redux/Settings/Line/lineSettingsSelectors";
import SettingsAPI from "api/SettingsAPI";

const Confirm = ({
  text,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
  disabled,
  onClose,
  children,
  isLoading = false,
}) => {
  return (
    <>
      <div className={s.confirm}>
        <p className={s.text}>{text}</p>
        {children}
        <div className={s.controls}>
          {!isLoading && (
            <>
              {agreeText && (
                <button
                  type="button"
                  onClick={onAgree}
                  disabled={disabled}
                  className={clsx(s.btn, s.btnAgree)}
                >
                  {agreeText}
                </button>
              )}
              <button
                type="button"
                onClick={onDisagree}
                className={clsx(s.btn, s.btnDisagree)}
              >
                {disagreeText}
              </button>
            </>
          )}
        </div>
      </div>
      <div className={g.overlay} onClick={isLoading ? null : onClose} />
    </>
  );
};

export const ConfirmLine = ({
  text,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
  disabled,
  onClose,
  children,
}) => {
  const [preStartsSettings, setPreStartSettings] = useState(null);
  const lineSettings = useSelector(getLineSettings);
  const [isPartNum, setIsPartNum] = useState(false);
  const [partNum, setPartNum] = useState("");

  const onPartNumnChange = (e) => {
    const value = e.target.value;
    setPartNum(value);
  };

  const getInfo = async () => {
    try {
      const res = await LineAPI.getPreStartLineInfo(lineSettings?.id);
      if (res?.productName || res?.consignmentNumber) {
        setPreStartSettings(res);
      } else {
        setPreStartSettings(null);
        onDisagree();
      }
    } catch (e) {
      setPreStartSettings(null);
      onDisagree();
    }
  };
  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineSettings?.id]);

  useEffect(() => {
    const getIsPartNum = async () => {
      const res = await SettingsAPI.getIsPartNumber();
      // const res = true;
      setIsPartNum(res);
    };
    getIsPartNum();
  }, []);

  return (
    <>
      {preStartsSettings && (
        <div
          className={
            preStartsSettings.consignmentSelected
              ? s.confirmLine
              : isPartNum
              ? s.confirmProductWpartNum
              : s.confirmProduct
          }
        >
          <div className={s.modalHeader}>Подтверждение запуска линии</div>
          <div className={s.modalContent}>
            <div className={s.commonContent}>
              {Object.entries(text).map((line) => (
                <p className={s.textRow} key={line.id}>
                  <span className={s.boldText}>{line[0]}</span>
                  <span className={s.text}>{line[1]}</span>
                </p>
              ))}
            </div>

            {!preStartsSettings.consignmentSelected && (
              <div className={s.productContent}>
                <p className={s.boldText}>
                  При закрытии задания будет установлена дата производства:
                </p>
                <div className={s.childrenContainer}> {children} </div>
                <p className={s.productText}>
                  При необходимости Вы можете поменять дату на необходимую Вам.
                </p>
                {isPartNum && (
                  <div className={s.isPartNumContainer}>
                    <p className={s.boldText}>Номер партии:</p>
                    <input
                      className={s.partuNumInput}
                      value={partNum}
                      // error={lineErrors.has("allowedNumberErrors")}
                      name="signal-more"
                      type="text"
                      onChange={onPartNumnChange}
                      containerStyles={s.containerStyles}
                    />
                  </div>
                )}
                <p className={s.productText}>
                  {`Количество свободных кодов маркировки ${preStartsSettings?.markingCodesRemainder}, если Вы сомневаетесь, что Вам хватит данного количества для выполнения смены, то сообщите административному персоналу для дополнительного заказа.`}
                </p>
              </div>
            )}
          </div>
          <div className={s.controls}>
            <button
              type="button"
              onClick={() => onAgree(partNum)}
              disabled={disabled}
              className={clsx(s.btn, s.btnAgree)}
            >
              {agreeText}
            </button>
            <button
              type="button"
              onClick={onDisagree}
              className={clsx(s.btn, s.btnDisagree)}
            >
              {disagreeText}
            </button>
          </div>
        </div>
      )}
      <div className={g.overlay} onClick={onClose} />
    </>
  );
};
export default Confirm;
