import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {consignmentCompleteIcon} from '../../../../../../constants'
import {stopLine} from '../../../../../../redux/Line/lineActionCreators'
import {getLineStatus} from '../../../../../../redux/Line/lineSelectors'
import s from './ConsignmentControlPanel.module.scss'

export const ConsignmentControlPanel = () => {
    const dispatch = useDispatch()
    const lineStatus = useSelector(getLineStatus)
    const [consignmentCompleted, setConsignmentCompleted] = useState(false)

    useEffect(() => {
        if (['RUN', 'PAUSE'].includes(lineStatus)) {
            setConsignmentCompleted(false)
        }
    }, [lineStatus])

    const onFinishConsignment = async () => {
        const res = await dispatch(stopLine())
        if (res.statusCode === 200) {
            setConsignmentCompleted(true)
        }
    }

    return (
        <button
            onClick={onFinishConsignment}
            className={clsx(s.consignmentControl, consignmentCompleted ? s.active : '')}
            disabled={!['RUN', 'PAUSE'].includes(lineStatus)}>
            {(['RUN', 'PAUSE'].includes(lineStatus) || consignmentCompleted) &&
            <>
                <div className={s.controlBtn}>
                    {consignmentCompleted &&
                    <img className={s.icon} src={consignmentCompleteIcon}
                         alt=""/>}
                </div>
                <span
                    className={s.label}>{consignmentCompleted ? 'Задание завершено' : 'Завершить задание'}</span>
            </>}
        </button>
    )
}
