import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addErrorControl,
  deleteErrorControl,
  validate,
} from "../../../../functions";
import { useChangingTimer } from "../../../../hooks";
import { updateWeighingSettings } from "../../../../redux/Settings/WeighingScale/weighingScaleSettingsReducer";
import { getWeighingScaleSettings } from "../../../../redux/Settings/WeighingScale/weighingScaleSettingsSelectors";
import Input from "../../../Common/Input/Input";
import Radio from "../../../Common/Radio/Radio";
import { ToggleCheckbox } from "../../../Common/ToggleCheckbox/ToggleCheckbox";
import "../../../Common/SettingsPanel/SettingsPanel.module.scss";
import panel from "../../../Common/SettingsPanel/SettingsPanel.module.scss";
import s from "./WeighingScaleSettingsPanel.module.scss";
import { SettingsPanel } from "../../../Common/SettingsPanel/SettingPanel";
import clsx from "clsx";

const WeighingScaleSettings = ({ errors, setErrors, setChanged }) => {
  const dispatch = useDispatch();
  const weighingScale = useSelector(getWeighingScaleSettings);

  const {
    name,
    active,
    connectionString,
    baudrate,
    dataBits,
    stopBits,
    parity,
    address,
  } = weighingScale;

  const [weighingErrors, setWeightingErrors] = useState(new Set());

  //useChangingTimer(setChanged)

  useEffect(() => {
    if (weighingErrors.size > 0) {
      addErrorControl("weighingScale", errors, setErrors);
    } else {
      deleteErrorControl("weighingScale", errors, setErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weighingErrors]);

  useEffect(() => {
    validate(
      {
        address,
        active,
        connectionString,
        baudrate,
        dataBits,
        stopBits,
        parity,
      },
      setWeightingErrors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weighingScale]);

  const toggleIsActive = () => {
    dispatch(updateWeighingSettings("active", !active));
    setChanged(true);
  };

  const changeWeighingData = (e) => {
    setChanged(true);
    dispatch(
      updateWeighingSettings(
        e.target.name,
        ["connectionString", "name"].includes(e.target.name)
          ? e.target.value
          : +e.target.value
      )
    );
  };

  return (
    <SettingsPanel name="Весы">
      <div className={panel.range}>
        <Input
          title="Адрес устройства"
          value={address}
          error={weighingErrors.has("address")}
          name="address"
          inputStyles={s.inputInput}
          containerStyles={s.input}
          onChange={changeWeighingData}
        />
        <Input
          title="Название устройства"
          error={weighingErrors.has("weighingName")}
          value={name}
          name="name"
          containerStyles={s.input}
          inputStyles={s.inputInput}
          onChange={changeWeighingData}
        />
      </div>
      <div className={panel.range}>
        <Input
          title="COM-порт"
          value={connectionString}
          error={weighingErrors.has("connectionString")}
          name="connectionString"
          inputStyles={s.inputInput}
          containerStyles={s.input}
          onChange={changeWeighingData}
        />
        <Input
          title="Количество битов"
          value={dataBits}
          error={weighingErrors.has("dataBits")}
          name="dataBits"
          inputStyles={s.inputInput}
          containerStyles={s.input}
          onChange={changeWeighingData}
        />
      </div>
      <div className={panel.range}>
        <Input
          title="Скорость обмена данными (бод/с)"
          value={baudrate}
          error={weighingErrors.has("baudrate")}
          name="baudrate"
          containerStyles={s.baudrateInput}
          inputStyles={s.inputInput}
          onChange={changeWeighingData}
        />
        <ToggleCheckbox
          label={"Активны"}
          checked={active}
          onChange={toggleIsActive}
          containerStyles={s.toggle}
        />
      </div>
      <div className={clsx(s.radioContainer)}>
        <span className={s.subtitle}>Бит чётности/нечётности</span>
        {[0, 1, 2, 3, 4].map((item) => {
          return (
            <Radio
              key={item}
              checked={parity === item}
              label={item}
              name="parity"
              value={item}
              onChange={changeWeighingData}
            />
          );
        })}
      </div>
      <div className={clsx(s.radioContainer, s.stopBits)}>
        <span className={s.subtitle}>Количество стоповых битов</span>
        {[0, 1, 2, 3].map((item) => {
          return (
            <Radio
              key={item}
              checked={stopBits === item}
              label={item}
              name="stopBits"
              value={item}
              onChange={changeWeighingData}
            />
          );
        })}
      </div>
    </SettingsPanel>
  );
};

export default WeighingScaleSettings;
