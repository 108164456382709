import React, {useEffect, useState} from 'react';
import HelpAPI from '../../../api/HelpAPI'

const HelpVideo = () => {
    const [title, setTitle] = useState('');
    const [video, setVideo] = useState('');

    useEffect(() => {
        HelpAPI.getVideoInstruction().then(res => {
            setTitle(res.title);
            setVideo(createYouTubeEmbedLink(res.video));
        });
    }, []);

    function createYouTubeEmbedLink(link) {
        let linkStart = 'https://www.youtube.com/embed/';
        let linkEnd = '?rel=0&showinfo=0';
        let isParams = link.indexOf('&', link.indexOf('?') + 1) !== -1;

        return isParams ?
            linkStart + link.slice(link.indexOf('=') + 1, link.indexOf('&')) + linkEnd :
            linkStart + link.slice(link.indexOf('=') + 1) + linkEnd
    }

    return (
        <div className="panel panel_instruction">
            <h1 className="instruction__title">{title}</h1>
            <div className="instruction__video">
                {!!video && <iframe title={'video'} src={video} frameBorder="0" className="instruction__video-video"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>}
            </div>
        </div>
    );
};

export default HelpVideo;
