import {SET_ALL_PLC_SETTINGS, UPDATE_PLC_SETTINGS} from './PLCSettingsActions'

const initialState = {plc: null}

export const PLCSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_PLC_SETTINGS:
            return {...action.settings}
        case UPDATE_PLC_SETTINGS:
            return {
                ...state,
                [action.key]: action.value
            }
        default:
            return state
    }
}
