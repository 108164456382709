import { setAllAggregationSettings } from "../Aggregation/aggregationSettingsActionCreators";
import { setAllLineSettings } from "../Line/lineSettingsActionCreators";
import { setAllPLCSettings } from "../PLC/PLCSettingsActionCreators";
import { setAllPrintingSettings } from "../Printing/printingSettingsActionCreators";
import { setAllScanningSettings } from "../Scanning/scanningSettingsActionCreators";
import { setAllWeighingScaleSettings } from "../WeighingScale/weighingScaleSettingsReducer";
import {
  SET_AGGREGATION_LEVEL,
  SET_LOADING,
  SET_TYPE_LINE,
  SET_IS_CLIENT,
  SET_SHOULD_SHOW_DEMO,
} from "./baseSettingsActions";
import SettingsAPI from "../../../api/SettingsAPI";

export const setLoading = (isLoading) => ({ type: SET_LOADING, isLoading });
const setIsClient = (isClient) => ({ type: SET_IS_CLIENT, payload: isClient });
const setShouldShowDemo = (showShow) => ({
  type: SET_SHOULD_SHOW_DEMO,
  payload: showShow,
});

export const setAllSettings = (settings) => async (dispatch) => {
  const { line, printing, scanning, aggregation, plc, weighingScale } =
    settings;
  dispatch(setAllLineSettings(line));
  dispatch(setAllAggregationSettings(aggregation));
  dispatch(setAllPrintingSettings(printing));
  dispatch(setAllScanningSettings(scanning));
  dispatch(setAllPLCSettings(plc));
  dispatch(setAllWeighingScaleSettings(weighingScale));
};

export const getIsClient = () => async (dispatch) => {
  const isActuallyAclient = await SettingsAPI.getIsClient();
  await dispatch(setIsClient(isActuallyAclient));
};

export const getShouldShowDemo = () => async (dispatch) => {
  const shouldShowDemoMode = await SettingsAPI.getShouldShowDemoMode();
  await dispatch(setShouldShowDemo(shouldShowDemoMode));
};

export const changeAggregationLevel = (aggregationLevel) => ({
  type: SET_AGGREGATION_LEVEL,
  aggregationLevel,
});

export const changeTypeLine = (payload) => ({
  type: SET_TYPE_LINE,
  payload,
});
