import clsx from 'clsx'
import React from 'react';
import s from './Checkbox.module.scss'

const Checkbox = ({name, ref, checked, label, value, onChange, id, disabled, styles}) => {
    return (
        <div className={clsx(s.checkbox, disabled ? s.disabled : '')} style={styles}>
            <input ref={ref} type="checkbox" name={name} id={id || value} disabled={disabled} checked={checked} onChange={onChange}
                   value={value} className={s.checkboxInput}/>
            <label htmlFor={id || value} className={s.checkboxLabel}>{label}</label>
        </div>
    );
};

export default Checkbox;
