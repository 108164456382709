import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../redux/Auth/authActionCreators";
import Input from "../Common/Input/Input";
import Loader from "../Common/Loader/Loader";
import s from "./Auth.module.scss";

export const Auth = ({ errorText, isLoading }) => {
  const dispatch = useDispatch();
  let [_login, setLogin] = useState("");
  let [_password, setPassword] = useState("");
  let formRef = useRef(null);

  const changeInputValue = (e) => {
    let val = e.target.value;
    if (e.target.name === "login") {
      setLogin(val);
    } else {
      setPassword(val);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(_login, _password));
  };

  return (
    <div className={s.overlay}>
      <container className={s.mainContainer}>
        <div className={s.logoContainer}>
          <svg
            width="180"
            height="180"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="120" height="120" rx="25" fill="#5589FF" />
            <rect
              x="32.3018"
              y="30"
              width="13.8135"
              height="14.4995"
              fill="#34DB93"
            />
            <rect
              x="46.1162"
              y="44.4983"
              width="13.8136"
              height="14.4995"
              fill="#FDFFFF"
            />
            <rect
              x="59.9277"
              y="59.002"
              width="13.8136"
              height="14.4994"
              fill="#FDFFFF"
            />
            <rect
              x="46.1113"
              y="59.002"
              width="13.8136"
              height="14.4994"
              fill="#FDFFFF"
            />
            <rect
              x="32.3018"
              y="73.5"
              width="13.8135"
              height="14.4995"
              fill="#FDFFFF"
            />
            <rect
              x="73.748"
              y="30"
              width="13.8135"
              height="14.4995"
              fill="#FDFFFF"
            />
            <rect
              x="73.748"
              y="73.5005"
              width="13.8135"
              height="14.4995"
              fill="#FDFFFF"
            />
          </svg>

          <span className={s.logoCompanyName}>HI-MARK</span>
        </div>

        <span className={s.pageTitle}>Введите логин и пароль</span>

        <form className={s.form} ref={formRef} onSubmit={onSubmit}>
          {isLoading && <Loader mini={true} />}
          <div className={s.inputContainer}>
            <Input
              name="login"
              title="ЛОГИН"
              value={_login}
              onChange={changeInputValue}
              isDefault={false}
              inputStyles={s.authInput}
              containerStyles={s.authInputDiv}
            />
            <Input
              name="password"
              type="password"
              value={_password}
              title="ПАРОЛЬ"
              onChange={changeInputValue}
              isDefault={false}
              inputStyles={s.authInput}
              containerStyles={s.authInputDiv}
            />
          </div>

          <div className={s.controls}>
            <button type="submit" className={s.login}>
              ВОЙТИ
            </button>
            {errorText && <span className={s.error}>{errorText}</span>}
          </div>
        </form>
      </container>
    </div>
  );
};
