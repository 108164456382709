import React from "react";
import g from "../../../../assets/styles/Main.module.scss";
import s from "./Alert.module.scss";

export const Alert = ({ text, closeText, onClose }) => {
  return (
    <>
      <div className={g.overlay} onClick={onClose} />
      <div className={s.alert}>
        <p className={s.text}>{text}</p>
        {!!closeText && (
          <button type="button" onClick={onClose} className={s.btn}>
            {closeText}
          </button>
        )}
      </div>
    </>
  );
};
