import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FULL_ACCESS } from "../../constants";
import { getAllLines, getUser } from "../../redux/App/appSelectors";
import { logout } from "../../redux/Auth/authActionCreators";
import { getAccessLevel } from "../../redux/Auth/authSelectors";
import {
  changeLine,
  getSelectedLine,
  setEmulationMode,
} from "../../redux/Line/lineActionCreators";
import {
  getEmulationMode,
  getLineStatus,
} from "../../redux/Line/lineSelectors";
import { Alert } from "../Common/Alerts/Alert/Alert";
import Confirm from "../Common/Alerts/Confirm/Confirm";
import { SelectingAlert } from "../Common/Alerts/SelectingAlert/SelectingAlert";
import { ToggleCheckbox } from "../Common/ToggleCheckbox/ToggleCheckbox";
import { ExitIcon } from "../SVG/ExitIcon";
import { RenderHeaderLogo } from "../SVG/Logo";
import { SelectLineBtn } from "./components/SelectLineBtn/SelectLineBtn";
import s from "./Header.module.scss";
import { lineStatusRussianEquivalents } from "../../functions";
import { getIsClient } from "../../redux/Settings/BaseSettings/baseSettingsSelectors";
import { getShowDemoMode } from "redux/Settings/BaseSettings/baseSettingsSelectors";
import AutoUpdateButton from "./autoUpdate/AutoUpdateButton";
import { getIsNewRelease } from "../../redux/App/appSelectors";
import clsx from "clsx";
import LineSelectingModal from "./LineSelectingModal";

const Header = () => {
  const dispatch = useDispatch();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [isSelectLineModalShowed, setIsSelectLineModalShowed] = useState(false);
  const [isConfirmShowed, setIsConfirmShowed] = useState(false);
  const line = useSelector(getSelectedLine);
  const lineStatus = useSelector(getLineStatus);
  const user = useSelector(getUser);

  const allLines = useSelector(getAllLines);
  const accessLevel = useSelector(getAccessLevel);
  const emulationMode = useSelector(getEmulationMode);
  const [isClient, setIsClient] = useState(true);
  const isClientAccount = useSelector(getIsClient);
  const shouldShowDemo = useSelector(getShowDemoMode);
  const isNewRelease = useSelector(getIsNewRelease);

  useEffect(() => {
    setIsClient(isClientAccount);
  }, []);

  const onSelectLine = (lineId) => {
    dispatch(changeLine(lineId));
    setIsSelectLineModalShowed(false);
  };

  // const onChangeEmulationMode = () => {
  //     lineStatus === 'RUN' || lineStatus === 'PAUSE' ?
  //         setShowDemoPopup(true) :
  //         dispatch(setEmulationMode(!emulationMode))
  // }

  const onChangeEmulationMode = useCallback(() => {
    lineStatus === "RUN" || lineStatus === "PAUSE"
      ? setShowDemoPopup(true)
      : dispatch(setEmulationMode(!emulationMode));
  }, [lineStatus]);

  return (
    <>
      {showDemoPopup && (
        <Alert
          text="Смена режима работы невозможна во время работы линии"
          closeText="Понятно"
          onClose={() => setShowDemoPopup(false)}
        />
      )}
      {isSelectLineModalShowed && (
        <LineSelectingModal
          lines={allLines}
          onSelectingLine={onSelectLine}
          lineId={line.id}
          closeAll={() => setIsSelectLineModalShowed(false)}
        />
      )}
      {showLogoutPopup && (
        <Alert
          text="Смена авторизации невозможна во время работы линии"
          closeText="Понятно"
          onClose={() => setShowLogoutPopup(false)}
        />
      )}
      <header className={s.header}>
        <div className={s.logoContainer}>
          <RenderHeaderLogo />
          <span className={s.logoText}>HI-MARK</span>
        </div>
        <div className={s.emptyContainer}></div>
        <div className={s.middleContainer}>
          <SelectLineBtn
            allLines={allLines}
            lineStatus={lineStatus}
            setIsSelectLineModalShowed={setIsSelectLineModalShowed}
          />
          <span className={s.lineStatus}>
            {lineStatusRussianEquivalents[lineStatus]}
          </span>
          <div className={s.lineStatusOuterIndicator}>
            <div
              className={clsx(s.lineStatusInnerIndicator, {
                [s.lineStatusIndicatatorStop]: lineStatus === "DISABLED",
                [s.lineStatusIndicatatorRun]: lineStatus === "RUN",
                [s.lineStatusIndicatatorPaused]: lineStatus === "PAUSE",
              })}
            />
          </div>
        </div>

        <div className={s.emptyContainer} />
        <AutoUpdateButton isUpdate={isNewRelease} />
        <div className={s.controls}>
          {accessLevel === FULL_ACCESS && shouldShowDemo && (
            <div className={s.demo}>
              <ToggleCheckbox
                checked={emulationMode}
                onChange={onChangeEmulationMode}
              />
              <span className={s.demoText}>Демо-режим</span>
            </div>
          )}
          {/*<button className={s.help} onClick={() => setIsOpenedHelp(prev => !prev)}>?</button>*/}
          <div className={s.login}>
            <span className={s.avatar}>
              {!!user &&
                `${user.firstName[0].toUpperCase()}${user.surname[0].toUpperCase()}`}
            </span>
            <button
              className={s.logout}
              title="Выход"
              onClick={() =>
                lineStatus === "RUN" || lineStatus === "PAUSE"
                  ? setShowLogoutPopup(true)
                  : setIsConfirmShowed(true)
              }
            >
              <ExitIcon />
            </button>
          </div>
        </div>
      </header>
      {isConfirmShowed && (
        <Confirm
          text="Вы действительно хотите выйти?"
          agreeText="Выйти"
          disagreeText="Отмена"
          onDisagree={() => setIsConfirmShowed(false)}
          onAgree={() => {
            setIsConfirmShowed(false);
            dispatch(logout());
          }}
        />
      )}
    </>
  );
};

export default Header;
