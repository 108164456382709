import {
    ADD_SCANNER,
    CHANGE_SCANNER_MODE,
    CHANGE_SCANNER_TYPE,
    DELETE_SCANNER,
    SET_ALL_SCANNING_SETTINGS,
    UPDATE_SCANNER_DATA
} from './scanningSettingsActions'

const initialState = { scanning: null }

export const scanningSettingsReducer = (state = initialState, action) => {
    const oldScanners = state?.scanners ? state?.scanners : []
    switch (action.type) {
        case SET_ALL_SCANNING_SETTINGS:
            return { ...action.settings }
        case ADD_SCANNER:
            return {
                ...state,
                type: action.scannerType,
                scanners: [...oldScanners, action.newScanner]
            }
        case DELETE_SCANNER:
            return {
                ...state,
                type: action.scannerType,
                scanners: [...oldScanners.filter(item => +item.id !== +action.scannerID)]
            }
        case CHANGE_SCANNER_TYPE:
            return {
                ...state,
                type: action.scannerType,
                scanners: [...oldScanners]
            }
        case CHANGE_SCANNER_MODE:
            return {
                ...state,
                scanners: [...oldScanners.map(item => +item.id === +action.scannerID ? {
                    ...item,
                    mode: action.scannerMode
                } : item)]
            }
        case UPDATE_SCANNER_DATA:
            return {
                ...state,
                type: action.scannerType,
                scanners: [...oldScanners.map(item => +item.id === +action.updatedScanner.id ? action.updatedScanner : item)]
            }
        default:
            return state
    }
}

