import s from "./AutoUpdate.module.scss";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Alert } from "components/Common/Alerts/Alert/Alert";
import Confirm from "components/Common/Alerts/Confirm/Confirm";
import releaseApi from "api/ReleaseApi";
import CountDownModal from "./CoundownModal";
import { useDispatch, useSelector } from "react-redux";
import { setShouldRetry } from "redux/App/releaseActionCreators";
import {
  getShouldRetryWS,
  getReleaseVersion,
  getReleaseIntallStatus,
} from "redux/App/appSelectors";
import { getLineStatus } from "redux/Line/lineSelectors";
import EmtpyDiv from "redux/App/Autoupdate/EmptyDiv";
import { getIsUpdateManualMode } from "redux/App/appSelectors";

const getStatusInfoText = (releaseStatus, releaseVersion, hasBeenRefreshed) => {
  switch (releaseStatus) {
    case 1:
      return "Ожидается назначения нового релиза";
    case 2:
      return "Идет подготовка к установке нового релиза";
    case 3:
      return "Идет загрузка установочных файлов";
    case 4:
      return "Произошла ошибка загрузки установочных файлов";
    case 5:
      return `Доступно обновление до версии ${releaseVersion}`;
    case 7:
      return "Произошла ошибка подготовки к установке";
    case 8:
    case 13:
      return "Произошла ошибка запуска установки нового релиза";
    case 9:
      return "Установка временно отложена. Новый цикл установки начнется чере 2 минуты.";
    case 14:
    case 15:
    case 16:
      return hasBeenRefreshed ? "Установка обновления скоро завершится" : "";
    default:
      return "";
  }
};

let retryTimeout;

const AutoUpdateButton = ({ isUpdate }) => {
  const shouldRetry = useSelector(getShouldRetryWS);
  const releaseVersion = useSelector(getReleaseVersion);
  const releaseIntallationStatus = useSelector(getReleaseIntallStatus);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateCD, setShowUpdateCD] = useState(false);
  const [startChecking, setStartChecking] = useState(false);
  const [error, setError] = useState(false);
  const [isUpdStarted, setIsUpdStarted] = useState(false);
  const lineStatus = useSelector(getLineStatus);
  const isUpdateManualMode = useSelector(getIsUpdateManualMode);

  const isWorkingLine = useCallback(() => {
    return lineStatus === "RUN" || lineStatus === "PAUSE";
  }, [lineStatus]);

  const handleErrorAlertClose = () => setError(false);

  const showModalWloader = () => {
    if (!isWorkingLine()) {
      setShowUpdateCD(false);
      setShowAlert(true);
      setIsLoading(true);
    }
  };

  function startRetry(ms) {
    const reTry = () => {
      setStartChecking(true);
      dispatch(setShouldRetry(true));
    };
    // step 4
    retryTimeout = setTimeout(() => reTry(), ms);
  }

  // step 1
  const showModalNoLoader = () => {
    if (!isWorkingLine()) {
      setShowUpdateCD(false);
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  // step 2
  const startAutomaticUpdate = useCallback(async () => {
    if (!isWorkingLine()) {
      try {
        setShowAlert(true);
        setShowAlert(false);
        setIsUpdStarted(true);
        setShowUpdateCD(true);
      } catch (e) {
        console.log("error-from-startUpdate", e);
        hideAll();
        if (isUpdateManualMode) {
          setError(true);
        }
      }
    }
  }, [isUpdateManualMode, isWorkingLine]);

  const startManualUpdate = useCallback(async () => {
    if (!isWorkingLine()) {
      try {
        setShowAlert(true);
        setIsLoading(true);
        await releaseApi.startUpdate();
        setShowAlert(false);
        setIsLoading(false);
        setIsUpdStarted(true);
        setShowUpdateCD(true);
      } catch (e) {
        console.log("error-from-startUpdate", e);
        hideAll();
        if (isUpdateManualMode) {
          setError(true);
        }
      }
    }
  }, [isUpdateManualMode, isWorkingLine]);

  // step 3
  const onStartLoader = async () => {
    try {
      showModalWloader();
      await releaseApi.confirmUILock();
      localStorage.setItem("UPDATE_STATUS", "STARTED");
      startRetry(360000);
    } catch (e) {
      console.log("error-from-confirmUILock", e);
      hideAll();
      setError(true);
    }
  };

  // step 5
  const afterUpdate = () => {
    clearTimeout(retryTimeout);
    retryTimeout = "canceled";
    localStorage.setItem("UPDATE_STATUS", "PAGE_REFRESHED");
    window.onbeforeunload = function () {};
    window.location.reload(false);
  };

  const hideAll = () => {
    setShowUpdateCD(false);
    setShowAlert(false);
    setIsLoading(false);
    setStartChecking(false);
    clearTimeout(retryTimeout);
    setIsUpdStarted(false);
    localStorage.setItem("UPDATE_STATUS", "DID_NOT_START");
  };

  const hideModal = () => {
    if (isLoading) {
      return;
    } else {
      setShowAlert(false);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("UPDATE_STATUS") === "STARTED" ||
      localStorage.getItem("UPDATE_STATUS") === "PAGE_REFRESHED"
    ) {
      if (releaseIntallationStatus > 6) {
        showModalWloader();
      }
    }
  }, []);

  useEffect(() => {
    if (startChecking && !shouldRetry) {
      afterUpdate();
    }
  }, [startChecking, shouldRetry]);

  const finishResUpd = useCallback(
    async (isFirstTime) => {
      if (isFirstTime) {
        afterUpdate();
      } else {
        try {
          setStartChecking(false);
          clearTimeout(retryTimeout);
          await releaseApi.confirmFinishUpdate();
        } catch (e) {
          console.log("error-from-confirmFinishUpdate", e);
          hideAll();
          if (isUpdateManualMode) {
            setError(true);
          }
        }
      }
    },
    [isUpdateManualMode]
  );

  useEffect(() => {
    if (releaseIntallationStatus > 0 && releaseIntallationStatus < 7) {
      localStorage.setItem("UPDATE_STATUS", "DID_NOT_START");
      if (releaseIntallationStatus < 5) {
        setShowAlert(false);
      }
    }
    if (releaseIntallationStatus === 6 && !isUpdStarted) {
      startAutomaticUpdate();
    }
    if (
      isUpdate &&
      (releaseIntallationStatus === 14 ||
        releaseIntallationStatus === 15 ||
        releaseIntallationStatus === 16)
    ) {
      if (localStorage.getItem("UPDATE_STATUS") === "PAGE_REFRESHED") {
        finishResUpd(false);
      } else {
        finishResUpd(true);
      }
    }
    if (releaseIntallationStatus === 9) {
      hideAll();
    }
    if (
      releaseIntallationStatus === 7 ||
      releaseIntallationStatus === 8 ||
      releaseIntallationStatus === 13
    ) {
      hideAll();
      if (!isWorkingLine() && isUpdateManualMode) {
        setError(true);
      }
    }
  }, [
    releaseIntallationStatus,
    isUpdateManualMode,
    finishResUpd,
    isUpdate,
    isUpdStarted,
    isWorkingLine,
    startAutomaticUpdate,
  ]);

  return (
    <>
      {isUpdate ? (
        <main className={s.autoUpdateButton}>
          <span className={s.autoUpdateButtonText} onClick={showModalNoLoader}>
            Доступно обновление
          </span>
          {showAlert && (
            <Confirm
              text={
                isLoading
                  ? "Во избежание ошибок программного обеспечения, не выключайте оборудование в процессе установки обновления, не перезагружайте страницу и не закрывайте браузер!"
                  : getStatusInfoText(releaseIntallationStatus, releaseVersion)
              }
              agreeText={releaseIntallationStatus === 5 ? "Установить" : ""}
              onAgree={
                releaseIntallationStatus === 5 ? startManualUpdate : null
              }
              onDisagree={() => setShowAlert(false)}
              disagreeText={"Закрыть"}
              onClose={() => setShowAlert(false)}
              isLoading={isLoading}
            >
              <EmtpyDiv
                isLoading={isLoading}
                customStyle={
                  isLoading
                    ? {
                        height: "5rem",
                        marginTop: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }
                    : {}
                }
              />
            </Confirm>
          )}
          {showUpdateCD && (
            <Confirm
              text={""}
              onDisagree={hideModal}
              disagreeText={"Закрыть"}
              onClose={() => {
                return;
              }}
              isLoading={true}
            >
              <CountDownModal
                hideModal={hideAll}
                onStartLoader={onStartLoader}
              />
            </Confirm>
          )}
          {error && (
            <Alert
              text="Не удалось запустить установку обновления"
              closeText="OK"
              onClose={handleErrorAlertClose}
            />
          )}
        </main>
      ) : null}
    </>
  );
};

export default memo(AutoUpdateButton);
