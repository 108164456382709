import {
    ADD_PRINTER,
    CHANGE_PRINTER_TYPE,
    DELETE_PRINTER,
    SET_ALL_PRINTING_SETTINGS,
    UPDATE_PRINTER_DATA
} from './printingSettingsActions'

const initialState = {printing: null}

export const printingSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_PRINTING_SETTINGS:
            return {...action.settings}
        case ADD_PRINTER:
            const oldPrinters = state?.printers ? state?.printers : []
            return {
                ...state,
                type: action.printerType,
                printers: [...oldPrinters, action.newPrinter]
            }
        case DELETE_PRINTER:
            return {
                ...state,
                type: action.printerType,
                printers: [...state.printers.filter(item => +item.id !== +action.printerID)]
            }
        case CHANGE_PRINTER_TYPE:
            return {
                ...state,
                type: action.printerType,
                printers: state?.printers
            }
        case UPDATE_PRINTER_DATA:
            return {
                ...state,
                type: action.printerType,
                printers: [...state.printers.map(item => +item.id === +action.updatedPrinter.id ? action.updatedPrinter : item)]
            }
        default:
            return state
    }
}