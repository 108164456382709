import { capacity, productLevels, codeStatuses } from "../../constants";
import { returnState } from "../../functions";
import { SET_EMULATION_MODE } from "../Settings/Line/lineSettingsActions";
import {
  ACTIVATE_ERROR_SIGNAL,
  ACTIVATE_STOP_SIGNAL,
  PAUSE_LINE,
  RESET_LINE,
  RUN_LINE,
  SET_LINE_RUNNED,
  SET_LINE_STATS,
  SET_LINE_STOP,
  SET_LOADING_CONTROL,
  SET_PRODUCTION_STATS,
  SET_SELECTED_CONSIGNMENT,
  STOP_LINE,
  UPDATE_PRODUCTS_TABLE,
  UPDATE_PRODUCTS_TABLE_FOR_DROPOUT,
  SET_LINE_PRODUCTION_TIME,
  SET_LINE_ITEM_DROPOUT,
  SET_LINE_ITEM_SUCCESS,
  GET_SCANNED_ITEM_PRODUCTLEVEL,
  GET_PRODUCT_INFO,
  SET_PRELOADED_STATS,
  SET_BOX_FORMED,
  SET_IS_LINE_LOADING,
} from "./lineActions";

const initialState = {
  isDropout: false,
  loadingControl: "",
  lineStop: false,
  activatedLineStopSignal: false,
  activatedLineErrorSignal: false,
  lineStatus: "DISABLED",
  lineRunned: false,
  item: null,
  isLineLoading: false,
  itemsTable: [],
  productsTable: [],
  boxesTable: [],
  palletsTable: [],
  productsStats: { total: 0, valid: 0, notValid: 0, error: 0, dropout: 0 },
  boxesStats: { total: 0, valid: 0, notValid: 0, error: 0 },
  palletsStats: { total: 0, valid: 0, notValid: 0, error: 0 },
  productionStats: { products: 0, boxes: 0, pallets: 0, totalWeight: 0 },
  emulationMode: false,
  selectedConsignment: null,
  lastUpdateCounterProducts: 0,
  lastUpdateCounterBoxes: 0,
  lastUpdateCounterPallets: 0,
  lastUpdateValidCounterProducts: 0,
  preloadedStats: {
    item: { total: 0, valid: 0, notValid: 0, error: 0, dropout: 0, level: 0 },
    box: { total: 0, valid: 0, notValid: 0, error: 0, level: 1 },
    pallet: { total: 0, valid: 0, notValid: 0, error: 0, level: 2 },
  },
};

const lineReducer = (state = initialState, action) => {
  switch (action.type) {
    case RUN_LINE:
      return {
        ...state,
        lineStatus: "RUN",
      };
    case STOP_LINE:
      return {
        ...state,
        lineStatus: "STOP",
      };
    case PAUSE_LINE:
      return {
        ...state,
        lineStatus: "PAUSE",
      };
    // ------------- generate stats for table
    case SET_LINE_STATS:
      const { total, valid, notValid, error, level, dropout } = action;

      switch (level) {
        case 0:
          return {
            ...state,
            productsStats: { total, valid, notValid, error, dropout },
          };
        case 1:
          return {
            ...state,
            boxesStats: { total, valid, notValid, error },
          };
        case 2:
          return {
            ...state,
            palletsStats: { total, valid, notValid, error },
          };

        default:
          return;
      }

    // generate stats for info-table
    case SET_PRODUCTION_STATS:
      const { products, boxes, pallets, totalWeight } = action;
      return {
        ...state,
        productionStats: { products, boxes, pallets, totalWeight },
      };
    case UPDATE_PRODUCTS_TABLE_FOR_DROPOUT:
      const { DOCode } = action;
      return {
        ...state,
        itemsTable: [...state.itemsTable].filter(
          (code) => code.Code !== DOCode
        ),
      };

    case UPDATE_PRODUCTS_TABLE:
      return {
        ...state,
        lastUpdateValidCounterProducts: action.items.filter(
          (el) =>
            el.ProductLevel === productLevels.PRODUCT && el.Status === "SUCCESS"
        ).length,
        lastUpdateCounterProducts: action.items.filter(
          (el) => el.ProductLevel === productLevels.PRODUCT
        ).length,
        lastUpdateCounterBoxes: action.items.filter(
          (el) => el.ProductLevel === productLevels.BOX
        ).length,
        lastUpdateCounterPallets: action.items.filter(
          (el) => el.ProductLevel === productLevels.PALLET
        ).length,
        itemsTable:
          state.itemsTable.length < capacity
            ? [...action.items, ...state.itemsTable].sort((a, b) => b.Id - a.Id)
            : [...action.items, ...state.itemsTable]
                .slice(0, capacity - 2)
                .sort((a, b) => b.Id - a.Id),
      };
    case RESET_LINE:
      return initialState;
    case SET_LINE_RUNNED:
      return returnState(state, action, "lineRunned", "isRunned");
    case SET_LOADING_CONTROL:
      return returnState(state, action, "loadingControl", "controlName");
    case SET_LINE_STOP:
      return returnState(state, action, "lineStop", "isLineStop");
    case ACTIVATE_STOP_SIGNAL:
      return returnState(state, action, "activateLineStopSignal", "activate");
    case ACTIVATE_ERROR_SIGNAL:
      return returnState(state, action, "activateLineErrorSignal", "activate");
    case SET_SELECTED_CONSIGNMENT:
      return returnState(state, action, "selectedConsignment", "consignment");
    case SET_EMULATION_MODE:
      return { ...state, emulationMode: action.emulationMode };
    case SET_LINE_PRODUCTION_TIME:
      return { ...state, lineProductionTime: action.payload };
    case SET_LINE_ITEM_DROPOUT:
      return { ...state, isDropout: action.payload };
    case SET_LINE_ITEM_SUCCESS:
      return { ...state, isSuccess: action.payload };
    case GET_SCANNED_ITEM_PRODUCTLEVEL:
      return { ...state, productLevel: action.payload };
    case GET_PRODUCT_INFO:
      return { ...state, productInfo: action.payload };
    case SET_PRELOADED_STATS:
      return { ...state, preloadedStats: action.payload };
    case SET_IS_LINE_LOADING:
      return { ...state, isLineLoading: action.payload };
    default:
      return state;
  }
};

export default lineReducer;
