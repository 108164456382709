import {returnState} from '../../functions'
import {SET_ACCESS_LEVEL, SET_AUTH_ERROR_TEXT, SET_IS_LOADING, SET_LOGGED} from './authActions'

const initialState = {
    logged: false,
    isLoading: false,
    authErrorText: '',
    accessLevel: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGGED:
            return returnState(state, action, 'logged', 'isLogged')
        case SET_IS_LOADING:
            return returnState(state, action, 'isLoading')
        case SET_AUTH_ERROR_TEXT:
            return returnState(state, action, 'authErrorText', 'text')
        case SET_ACCESS_LEVEL:
            return returnState(state, action, 'accessLevel', 'level')
        default:
            return state
    }
}

export default authReducer
