import React, {useState} from 'react'
import Slider from 'react-slick'
import g from '../../../../assets/styles/Main.module.scss'
import s from './SliderPopup.module.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const SliderPopup = ({images, onClose}) => {
    let [curSlide, setCurSlide] = useState(1)

    const onChangeSlide = e => {
        setCurSlide(e + 1)
    }
    const settings = {
        dots: false,
        arrows: false,
        centerMode: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: onChangeSlide
    }

    return (
        <>
        <div className={g.overlay} onClick={onClose}/>
            <div className={s.slider}>
                <div className={s.count}>
                    <span>{curSlide}</span>/<span>{images.length}</span>
                </div>
                <Slider {...settings}>
                    {images.map((item, i) => {
                        return <img key={i} src={`http://213.139.208.45${item}`} alt="" className={s.pic}/>
                    })}
                </Slider>
            </div>
        </>
    )
}

export default SliderPopup
