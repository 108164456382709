export const getIsOpenedSettings = (state) => state.app.isOpenedSettings;
export const getErrorText = (state) => state.app.errorText;
export const getErrorTextSocket = (state) => state.app.errorTextSocket;
export const getUser = (state) => state.app.user;
export const getAllLines = (state) => state.app.allLines;
export const getRole = (state) => state.app.user?.role;
export const getSelectedDisplayMode = (state) => state.app.selectedDisplayMode;
export const getIsLoading = (state) => state.app.loading;
export const getIsAggregationIncluded = (state) =>
  state.app.isAggregationIncluded;
export const getIsNewRelease = (state) => state.app.isNewRelease;
export const getShouldRetryWS = (state) => state.app.shouldRetry;
export const getReleaseVersion = (state) => state.app.releaseVersion;
export const getReleaseIntallStatus = (state) => state.app.releaseInstallStatus;
export const getInstalledRelVersion = (state) =>
  state.app.installedReleaseVersion;
export const getInstRelVerInfo = (state) => state.app.installedRelVerInfo;
export const getIsUpdateManualMode = (state) => state.app.isUpdateManualMode;
