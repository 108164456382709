export const SET_All_AGGREGATION_SETTINGS = 'SET_All_AGGREGATION_SETTINGS'
export const ADD_MULTISCANNER = 'ADD_MULTISCANNER'
export const ADD_APPLICATION_PRINTER = 'ADD_APPLICATION_PRINTER'
export const ADD_APPLICATION_SCANNER = 'ADD_APPLICATION_SCANNER'
export const DELETE_MULTISCANNER = 'DELETE_MULTISCANNER'
export const DELETE_APPLICATION_PRINTER = 'DELETE_APPLICATION_PRINTER'
export const DELETE_APPLICATION_SCANNER = 'DELETE_APPLICATION_SCANNER'
export const UPDATE_MULTISCANNER_DATA = 'UPDATE_MULTISCANNER_DATA'
export const UPDATE_APPLICATION_PRINTER_DATA = 'UPDATE_APPLICATION_PRINTER_DATA'
export const UPDATE_APPLICATION_SCANNER_DATA = 'UPDATE_APPLICATION_SCANNER_DATA'
export const CHANGE_MULTISCANNER_MODE = 'CHANGE_MULTISCANNER_MODE'
export const CHANGE_APPLICATION_SCANNER_MODE = 'CHANGE_APPLICATION_SCANNER_MODE'
export const CHANGE_AGGREGATION_TYPE = 'CHANGE_AGGREGATION_TYPE'
export const CHANGE_APPLICATION_TYPE = 'CHANGE_APPLICATION_TYPE'
export const CHANGE_MULTISCANNING_TYPE = 'CHANGE_MULTISCANNING_TYPE'
export const UPDATE_NUMBER_PACKAGES = 'UPDATE_NUMBER_PACKAGES'
export const SET_FLOAT_NUMBER_PACKAGES = 'SET_FLOAT_NUMBER_PACKAGES'

