import clsx from "clsx";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FULL_ACCESS } from "../../constants";
import { validateField } from "../../functions";
import { getAccessLevel } from "../../redux/Auth/authSelectors";
import {
  getIsSettingsLoading,
  getSettings,
} from "../../redux/Settings/BaseSettings/baseSettingsSelectors";
import Loader from "../Common/Loader/Loader";
import Confirm from "../Common/Alerts/Confirm/Confirm";
import SaveBtn from "../Common/SaveBtn";
import Control from "../Control/Control";
import AggregationSettingsPanel from "./SettingsPanels/AggregationSettingsPanel/AggregationSettingsPanel";
import LineSettingsPanel from "./SettingsPanels/LineSettingsPanel/LineSettingsPanel";
import PLCSettingsPanel from "./SettingsPanels/PLCSettingsPanel/PLCSettingsPanel";
import PrintingSettingsPanel from "./SettingsPanels/PrintingSettingsPanel/PrintingSettingsPanel";
import ScanningSettingsPanel from "./SettingsPanels/ScanningSettingsPanel/ScanningSettingsPanel";
import WeighingScaleSettings from "./SettingsPanels/WeighingScaleSettingsPanel/WeighingScaleSettings";
import g from "../../assets/styles/Main.module.scss";
import s from "./Settings.module.scss";
import { getIsAggregationIncluded } from "../../redux/App/appSelectors";

let controls = [
  {
    name: "back",
    title: "Назад",
    icon: require("../../assets/img/icons/back.png"),
  },
  { name: "printing", title: "Нанесение" },
  { name: "scanning", title: "Сканирование" },
  { name: "aggregation", title: "Агрегация" },
  { name: "plc", title: "ПЛК" },
  { name: "weighingScale", title: "Весы" },
];

const Settings = ({ openSettings, saveSettings, resetSettings }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getIsSettingsLoading);
  const accessLevel = useSelector(getAccessLevel);
  const settings = useSelector(getSettings);
  const [openTab, setOpenTab] = useState(
    [FULL_ACCESS].includes(accessLevel) ? "printing" : ""
  );
  const [changed, setChanged] = useState(false);
  const [errors, setErrors] = useState(new Set());
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptTitle, setPromptTitle] = useState("");
  const isAggregationIncluded = useSelector(getIsAggregationIncluded);

  if (!isAggregationIncluded)
    controls = controls.filter((button) => button.name !== "aggregation");

  const validateDevice = (device, setDeviceErrors) => {
    let newErrors = new Set();

    for (let key in device) {
      if (device.hasOwnProperty(key) && !validateField(key, device[key])) {
        newErrors.add(key);
      }
    }
    setDeviceErrors(newErrors);
  };

  return (
    <>
      <div className={clsx(g.wrapper, s.settings)}>
        <div className={g.content}>
          <aside className={clsx(g.controls, s.controls)}>
            {controls.map((item, i) => {
              return (
                <Control
                  key={i}
                  errors={errors}
                  changed={changed}
                  icon={item.icon}
                  text={item.title}
                  name={item.name}
                  openTab={openTab}
                  setOpenTab={setOpenTab}
                  toggleWindow={(isOpened) => dispatch(openSettings(isOpened))}
                  setShowPrompt={setShowPrompt}
                  setPromptTitle={setPromptTitle}
                  active={openTab === item.name}
                />
              );
            })}
            <SaveBtn
              disabled={!changed || errors.size > 0}
              error={errors.size > 0}
              onClick={() => dispatch(saveSettings())}
            />
          </aside>
          <div className={s.panels}>
            <LineSettingsPanel
              setChanged={setChanged}
              setErrors={setErrors}
              errors={errors}
            />
            {settings.printing && openTab === "printing" && (
              <PrintingSettingsPanel
                setChanged={setChanged}
                setErrors={setErrors}
                errors={errors}
                validateDevice={validateDevice}
              />
            )}
            {settings.scanning && openTab === "scanning" && (
              <ScanningSettingsPanel
                setChanged={setChanged}
                setErrors={setErrors}
                errors={errors}
                validateDevice={validateDevice}
              />
            )}
            {settings.aggregation && openTab === "aggregation" && (
              <AggregationSettingsPanel
                setChanged={setChanged}
                setErrors={setErrors}
                errors={errors}
                validateDevice={validateDevice}
              />
            )}
            {settings.plc && openTab === "plc" && (
              <PLCSettingsPanel
                setChanged={setChanged}
                setErrors={setErrors}
                errors={errors}
              />
            )}
            {settings.weighingScale && openTab === "weighingScale" && (
              <WeighingScaleSettings
                setChanged={setChanged}
                setErrors={setErrors}
                errors={errors}
              />
            )}
          </div>
        </div>
      </div>
      {showPrompt && (
        <Confirm
          text={promptTitle}
          agreeText="Сохранить"
          disagreeText="Отменить"
          disabled={errors.size > 0}
          onClose={() => setShowPrompt(false)}
          onAgree={() => {
            dispatch(saveSettings());
          }}
          onDisagree={() => {
            dispatch(resetSettings());
            setShowPrompt(false);
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default Settings;
